import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
} from '@material-ui/core';
import { GrClose } from 'react-icons/gr';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Link } from 'react-router-dom';
import HttpClient from '../../lib/HttpClient';
import { useSnackbarContext } from '../../contexts/Snackbar';
import utils from '../../Utils';

interface WaitingListDialogProps {
  closeFn: () => void;
}

interface WaitingListItem {
  // TODO: add the waiting list type
}

export default function WaitingListDialog ({
  closeFn,
}: WaitingListDialogProps) {
  const { setSnackbar } = useSnackbarContext();
  const userProfile = utils.retrieveUserProfile();
  const doctorProfile = utils.retrieveSelectDoctorProfile();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [updateCount, setUpdateCount] = useState<number>(0);
  const [waitingList, setWaitingList] = useState<any[]>([]);
  const minDate = new Date();
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    HttpClient.performGet<any>(
      `/api/v1/waiting/${userProfile.clinicId}?date=${selectedDate?.toISOString()
        .split('T')[0]}&ownerId=${doctorProfile.id}`)
      .then(({ body }) => {
        if (body?.waits && body.waits.length > 0) {
          setWaitingList(body?.waits);
        } else {
          setWaitingList([]);
        }
      }).catch(() => {
      setWaitingList([]);
    });
  }, [selectedDate, updateCount]);

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={true}
          onClose={closeFn}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            Waiting List
            <IconButton
              aria-label="close"
              onClick={closeFn}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <GrClose/>
            </IconButton>
          </DialogTitle>
          <Divider/>
          <DialogContent>
            <DialogContentText>
              <div>
                <KeyboardDatePicker
                  minDate={minDate}
                  disableToolbar
                  variant="inline"
                  format={'dd-MM-yyyy'}
                  id="date-picker-inline"
                  label="Select Date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  autoOk={true}
                />
              </div>
            </DialogContentText>
            <Divider/>
            <DialogContentText>
              <div className={'mt-2'}>
                <div className="main_content mt-0">
                  <div className="inner_content p-0">
                    <div className="appointment_block p-0">
                      <div className="appointment_body">
                        <div className="table-responsive">
                          <table className="custom_table table">
                            <thead>
                            <tr>
                              <th>
                                <span
                                  className="txt fw-bold">Patient Name</span>
                              </th>
                              <th>
                                <span className="txt fw-bold">Type</span>
                              </th>
                              <th>
                                <span
                                  className="txt fw-bold">Contact</span>
                              </th>
                              <th>
                                <span
                                  className="txt fw-bold">Location</span>
                              </th>
                              <th>
                                <span
                                  className="txt fw-bold">Note</span>
                              </th>
                              <th className="text-center th_last_col">
                                <span
                                  className="txt fw-bold">Book Appointment</span>
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            {(waitingList && waitingList.length > 0)
                              ? waitingList.map(
                                (wait) => {
                                  return (
                                    <tr key={wait.id}>
                                      <td>
                                        <span className="txt">{wait.attendeeMetadata.name}</span>
                                      </td>
                                      <td>
                                        <span className="txt">{wait.attendeeType}</span>
                                      </td>
                                      <td>
                                        <span
                                          className="txt">{wait.attendeeMetadata.mobile}</span>
                                      </td>
                                      <td>
                                        <span className="txt">{wait.location}</span>
                                      </td>
                                      <td>
                                        <span className="txt">{wait.note}</span>
                                      </td>
                                      <td className="text-center td_last_col">
                                        <a
                                          onClick={(e: React.MouseEvent<HTMLElement>) => {
                                            HttpClient.performPut<any>(
                                              `/api/v1/waiting/${wait.id}/appointment`)
                                              .then((response) => {
                                                if (!response?.body?.status?.success) {
                                                  setSnackbar({
                                                    message: response?.body?.status?.message,
                                                    severity: 'error',
                                                  });
                                                } else {
                                                  setSnackbar({
                                                    message: 'Added to today\'s appointment list successful!',
                                                    severity: 'success',
                                                  });
                                                  // todayAppointments.refresh();
                                                }
                                              })
                                              .catch((e) => {
                                                console.log(e);
                                              })
                                              .finally(() => {
                                                console.log('finally!');
                                                setUpdateCount(updateCount + 1);
                                              });
                                          }}
                                          className={`link_cancel me-2 btn`}
                                          style={{ cursor: 'pointer' }}
                                        >
                                          Today
                                        </a>
                                        <Link
                                          to={`/app-help?w=2&pid=${wait.attendeeMetadata.appId ??
                                          wait.attendeeMetadata.clinicId}`}
                                          className={`link_cancel me-2 btn`}>Later
                                          Date</Link>
                                      </td>
                                    </tr>
                                  );
                                })
                              : (
                                <tr>
                                  <td colSpan={6} className={'text-center'}>
                                    Waiting list is empty for selected date
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </MuiPickersUtilsProvider>
    </>
  );
}
