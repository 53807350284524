import AttendeeMetadata from "./AttendeeMetadata";
import SpaceMetadata from "./SpaceMetadata";
import OwnerMetadata from "./OwnerMetadata";

export default class Appointment {
  constructor(
    public readonly id: string,
    public readonly date: string,
    public readonly start_time: string,
    public readonly appointment_status: string,
    public readonly attendee_type: string,
    public readonly attendee_status: string,
    public readonly payment: string,
    public readonly attendee_metadata: AttendeeMetadata,
    public readonly owner_metadata: OwnerMetadata,
    public readonly space_metadata: SpaceMetadata,
    public readonly consultation: string,
    public readonly source: string,
    public readonly isOnline: boolean,
    public readonly queueNumber: number | null,
    public readonly vital: boolean | null,
    public readonly report: boolean | null
  ) {}
}
