import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  Paper,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ReactComponent as PDFIcon } from "../../img/icons/icon_pdf.svg";
import ImageFileIcon from "../../img/image-file-icon.png";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { useSnackbarContext } from "../../contexts/Snackbar";
import HttpClient from "../../lib/HttpClient";
import Profile from "../../models/domain/Profile";
import ProfileMapper from "../../mappers/ProfileMapper";
import UploadReportDialog from "../../components/reports/UploadReportDialog";
import utils from "../../Utils";
import { Spinner } from "react-bootstrap";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      alignItems: "center",
    },
    card: {
      padding: theme.spacing(1),
    },
    text: {
      fontSize: 12,
    },
    bottomNav: {
      padding: 16,
    },
    sections: {
      padding: 30,
    },
    pContainedButton: {
      backgroundColor: "#0060A6",
    },
    sContainedButton: {
      backgroundColor: "#f78f2d",
    },
    outlineButton: {
      color: "#0060A6",
    },
    divider: {
      background: "black",
    },
    table: {
      minWidth: 650,
    },
  })
);

class PendingReport {
  patientID: string = "";
  patientName: string = "";
  createdDate: string = "";
  constructor(patientID: string, patientName: string, createdDate: string) {
    this.patientID = patientID;
    this.patientName = patientName;
    this.createdDate = createdDate;
  }
}

class Report {
  id: string;
  name: string;
  url: string;
  note: string = "";
  noteType: string = "personalised-message";
  fileFormat: string;
  constructor(id: string, name: string, url: string, fileFormat: string) {
    this.id = id;
    this.name = name;
    this.url = url;
    this.fileFormat = fileFormat;
  }
}

export default function Reports() {
  const classes = useStyles();
  const userProfile = utils.retrieveUserProfile();
  let [deleteDialog, setDeleteDialog] = React.useState(false);
  let [reportsLoaded, setReportsLoaded] = React.useState(false);
  let [patientLoaded, setPatientLoaded] = React.useState(true);
  let [patientReportsLoaded, setPatientReportsLoaded] = React.useState(true);

  let [reportID, setReportID] = React.useState("");
  let [patientExists, setPatientExists] = React.useState(false);
  let [uploadReports, setUploadReports] = React.useState(false);
  let [addNotes, setAddNotes] = React.useState(false);
  let [multipleProfiles, setMultipleProfiles] = React.useState<Profile[]>([]);
  let [showDialog, setShowDialog] = React.useState(false);
  const { setSnackbar } = useSnackbarContext();
  const stdNote =
    "Your reports have been seen by Dr. Akerkar. Please continue with the same medications.";

  let [patientId, setID] = React.useState("");
  const handleIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setID(event.target.value);
  };

  let [patientPhone, setPhone] = React.useState("");
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };

  let [patientName, setName] = React.useState("");
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  let [patientLName, setLName] = React.useState("");
  const handleLNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLName(event.target.value);
  };

  let [patientEmail, setEmail] = React.useState("");
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  function createData(no: number, ID: number, name: string) {
    return { no, ID, name };
  }

  let [pendingReports, setPendingReports] = React.useState<PendingReport[]>([]);
  let [patientReports, setPatientReports] = useState<Report[]>([]);

  const [pathologyNote, setPathValue] = React.useState("");
  const [bodyScanNote, setBodyScanValue] = React.useState("");

  const clearState = () => {
    setPatientReports([]);
    setPhone("");
    setName("");
    setID("");
    setEmail("");
    setLName("");
    setAddNotes(false);
    setPatientExists(false);
  };

  function fetchPendingReports() {
    setReportsLoaded(false);
    HttpClient.performGet<any>(
      `/api/v1/users/reports/pending/${userProfile.clinicId}`
    )
      .then(({ body }) => {
        if (body?.patients) {
          console.log(body);
          setPendingReports(
            body.patients.map(
              (value: any) =>
                new PendingReport(
                  value.patientId,
                  value.patientName,
                  value.reportUploadDate
                )
            )
          );
        } else {
          setSnackbar({
            message: body?.status?.message ?? "No pending reports",
            severity: "error",
          });
        }
      })
      .catch((e) => {
        setMultipleProfiles([]);
        setSnackbar({
          message: e.body?.status?.message,
          severity: "error",
        });
      })
      .finally(() => {
        setReportsLoaded(true);
      });
  }

  function fetchPatientReports() {
    setPatientReportsLoaded(false);
    HttpClient.performGet<any>(`/api/v1/users/reports/${patientId}`)
      .then(({ body }) => {
        if (body) {
          console.log(body);
          if (body?.reports) {
            console.log(body);
            setPatientReports(
              body.reports.map(
                (value: any) =>
                  new Report(
                    value.id,
                    value.name,
                    value.signedUrl,
                    value.fileFormat
                  )
              )
            );
          }
        } else {
          setSnackbar({
            message: body?.status?.message ?? "No pending reports",
            severity: "error",
          });
        }
      })
      .catch((e) => {
        setMultipleProfiles([]);
        setSnackbar({
          message: e.body?.status?.message,
          severity: "error",
        });
      })
      .finally(() => {
        setPatientReportsLoaded(true);
      });
  }

  function deleteReport(reportId: string) {
    HttpClient.performPut<any>(`/api/v1/users/reports/${reportId}`)
      .then(({ body }) => {
        if (body) {
          console.log(body);
          if (body.status.success) {
            setSnackbar({
              message: "Report deleted",
              severity: "success",
            });
            fetchPatientReports();
          } else {
            setSnackbar({
              message: body?.status?.message ?? "Unable to delete report",
              severity: "error",
            });
          }
        } else {
          setSnackbar({
            message: body?.status?.message ?? "Report not found",
            severity: "error",
          });
        }
      })
      .catch((e) => {
        setSnackbar({
          message: e.body?.status?.message,
          severity: "error",
        });
      });
  }

  function profileSearch() {
    setPatientLoaded(false);
    let getExistingUserUrl = ``;
    if (!patientId && !patientPhone) {
      setSnackbar({
        message:
          multipleProfiles?.length > 1
            ? "Please select one of the patients from the list"
            : "Please enter mobile number or patient id",
        severity: "error",
      });
      return;
    }
    if (patientId) {
      getExistingUserUrl = `/api/v1/users/search?patientId=${patientId}`;
    } else {
      getExistingUserUrl = `/api/v1/users/search?mobile=${patientPhone}`;
    }
    HttpClient.performGet<any>(getExistingUserUrl)
      .then(({ body }) => {
        if (
          body?.profiles &&
          body?.profiles.length === 1 &&
          body?.profiles[0]
        ) {
          setMultipleProfiles([]);
          if (body?.profiles[0].appId) {
            setName(body?.profiles[0].name);
            setID(body?.profiles[0].appId);
            setPhone(body?.profiles[0].mobile);
            setEmail(body?.profiles[0].email);
            setPatientExists(true);
          } else {
            setSnackbar({
              message: "Error fetching patient",
              severity: "error",
            });
          }
        } else if (body?.profiles && body?.profiles.length > 1) {
          setMultipleProfiles(
            body?.profiles.map(ProfileMapper.mapResponseToModel)
          );
        } else {
          setMultipleProfiles([]);
          setSnackbar({
            message: body?.status?.message ?? "Patient does not exist",
            severity: "error",
          });
        }
      })
      .catch((e) => {
        setMultipleProfiles([]);
        setSnackbar({
          message: e.body?.status?.message,
          severity: "error",
        });
      })
      .finally(() => {
        setPatientLoaded(true);
      });
    // fetch available reports
  }

  function addReportNote(reportId: string, note: String) {
    return HttpClient.performPatch<any>(`/api/v1/users/reports/${reportId}`, {
      note: note,
    })
      .then(({ body }) => {
        if (!(body && body.status?.success)) {
          return {
            message:
              body?.status?.message ??
              "Failed to add note for report with ID: " + reportId,
            severity: "error",
            success: body?.status.success,
          };
        } else {
          return {
            message: "Notes added successfully",
            severity: "success",
            success: true,
          };
        }
      })
      .catch((e) => {
        return {
          message: e.body?.status?.message,
          severity: "error",
          success: false,
        };
      });
  }

  useEffect(() => {
    fetchPendingReports();
  }, []);

  useEffect(() => {
    if (patientId && patientId.length === 8) {
      profileSearch();
      fetchPatientReports();
    }
  }, [patientId]);

  const ReportListing = patientReports.map((report) => (
    <div key={report.id}>
      <Grid item xs={8}>
        <Paper variant="outlined" className={classes.card}>
          <Grid container>
            <Grid item xs={1}>
              {report?.fileFormat === "png" ||
              report?.fileFormat === "jpg" ||
              report?.fileFormat === "jpeg" ? (
                <img
                  style={{ width: "40px", height: "auto" }}
                  src={ImageFileIcon}
                  alt=""
                  className="img-fluid"
                />
              ) : (
                <PDFIcon />
              )}
            </Grid>
            <Grid item xs={10}>
              <a href={report.url} target="_blank">
                <label className={classes.text}>{report.name}</label>
              </a>
            </Grid>
            {/*<Grid item xs={4}><label className={classes.text}>date here</label></Grid>*/}
            <Grid item xs={1}>
              <DeleteOutlinedIcon
                style={{ color: "lightgray" }}
                onClick={() => {
                  setDeleteDialog(true);
                  setReportID(report.id);
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {addNotes && (
        <>
          <Grid item xs style={{ marginBottom: "0.5em", marginTop: "0.5em" }}>
            <label className={classes.text}>
              {report.name.indexOf("Pathology") !== -1
                ? "Notes for pathology report"
                : "Notes for body scan report"}
            </label>
          </Grid>
          <Grid item xs style={{ marginBottom: "0.5em" }}>
            {report.name.indexOf("Pathology") !== -1 ? (
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="noteType"
                  name="noteType"
                  value={pathologyNote}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    report.noteType = event.target.value;
                    setPathValue((event.target as HTMLInputElement).value);
                  }}
                >
                  <FormControlLabel
                    value="standard-message"
                    control={<Radio />}
                    label="Standard Messsage"
                    onClick={() => {
                      report.note = stdNote;
                    }}
                  />
                  <FormControlLabel
                    value="personalised-message"
                    control={<Radio />}
                    label="Personalised Message"
                    onClick={() => {
                      report.note = "";
                    }}
                  />
                </RadioGroup>
              </FormControl>
            ) : (
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="noteType"
                  name="noteType"
                  value={bodyScanNote}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    report.noteType = event.target.value;
                    setBodyScanValue((event.target as HTMLInputElement).value);
                  }}
                >
                  <FormControlLabel
                    value="standard-message"
                    control={<Radio />}
                    label="Standard Messsage"
                    onClick={() => {
                      report.note = stdNote;
                    }}
                  />
                  <FormControlLabel
                    value="personalised-message"
                    control={<Radio />}
                    label="Personalised Message"
                    onClick={() => {
                      report.note = "";
                    }}
                  />
                </RadioGroup>
              </FormControl>
            )}
          </Grid>
          <Grid item xs style={{ marginBottom: "1.5em" }}>
            <TextField
              id="outlined-textarea"
              multiline
              variant="outlined"
              rows={5}
              fullWidth
              value={
                report.name.indexOf("Pathology") !== -1
                  ? report.noteType === "standard-message"
                    ? report.note
                    : null
                  : report.noteType === "standard-message"
                  ? report.note
                  : null
              }
              onChange={(e) => {
                report.note = e.target.value;
              }}
              disabled={
                report.name.indexOf("Pathology") !== -1
                  ? report.noteType === "standard-message"
                    ? true
                    : false
                  : report.noteType === "standard-message"
                  ? true
                  : false
              }
            />
          </Grid>
          <Grid item xs style={{ marginBottom: "1.5em" }}>
            <button
              className="primary_button"
              onClick={async () => {
                let result = undefined;
                let isInvalid = false;
                // Validate
                if (!report.note) {
                  isInvalid = true;
                }
                if (isInvalid) {
                  setSnackbar({
                    message: "Notes cannot be empty",
                    severity: "error",
                  });
                } else {
                  // Add notes
                  result = await addReportNote(report.id, report.note);

                  if (result) {
                    if (result.success) {
                      clearState();
                      setUploadReports(false);
                      fetchPendingReports();
                    }
                    setSnackbar({
                      message: result.message,
                      severity: result.success ? "success" : "error",
                    });
                  }
                }
              }}
            >
              Save Reports
            </button>
          </Grid>
        </>
      )}
      <Dialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete file"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete this file
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)}>No</Button>
          <Button
            onClick={() => {
              deleteReport(reportID);
              setDeleteDialog(false);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ));

  return (
    <div>
      {uploadReports ? (
        <div>
          {patientLoaded && patientReportsLoaded ? (
            <div>
              <div>
                <Grid container spacing={4} direction="row">
                  <Grid item xs>
                    <Grid
                      container
                      spacing={3}
                      direction="column"
                      className={classes.sections}
                    >
                      <Grid item xs>
                        <Grid
                          container
                          direction="row"
                          className={classes.grid}
                        >
                          <Grid item xs>
                            <label>Patient Type</label>
                          </Grid>
                          <Grid item xs>
                            <Chip
                              style={{
                                fontSize: "16px",
                                borderRadius: "4px",
                                width: "100px",
                                height: "50px",
                              }}
                              label="Existing"
                              color={"primary"}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs>
                        <Grid
                          container
                          direction="row"
                          className={classes.grid}
                        >
                          <Grid item xs>
                            <label>App Patient ID</label>
                          </Grid>
                          <Grid item xs>
                            <TextField
                              size="small"
                              variant="outlined"
                              color="secondary"
                              value={patientId}
                              fullWidth
                              onChange={handleIDChange}
                              disabled={patientExists}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs>
                        <Grid
                          container
                          direction="row"
                          className={classes.grid}
                        >
                          <Grid item xs>
                            <label>Mobile No</label>
                          </Grid>
                          <Grid item xs>
                            <TextField
                              size="small"
                              variant="outlined"
                              color="secondary"
                              value={patientPhone}
                              fullWidth
                              onChange={handlePhoneChange}
                              disabled={patientExists}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {patientName && (
                        <Grid item xs>
                          <Grid
                            container
                            direction="row"
                            className={classes.grid}
                          >
                            <Grid item xs>
                              <label>Name</label>
                            </Grid>
                            <Grid item xs>
                              <TextField
                                size="small"
                                variant="outlined"
                                color="secondary"
                                value={patientName}
                                fullWidth
                                disabled
                                onChange={handleNameChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {patientLName && (
                        <Grid item xs>
                          <Grid
                            container
                            direction="row"
                            className={classes.grid}
                          >
                            <Grid item xs>
                              <label>Last Name</label>
                            </Grid>
                            <Grid item xs>
                              <TextField
                                size="small"
                                variant="outlined"
                                color="secondary"
                                value={patientLName}
                                fullWidth
                                disabled
                                onChange={handleLNameChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {patientEmail && (
                        <Grid item xs>
                          <Grid
                            container
                            direction="row"
                            className={classes.grid}
                          >
                            <Grid item xs>
                              <label>Email ID</label>
                            </Grid>
                            <Grid item xs>
                              <TextField
                                size="small"
                                variant="outlined"
                                color="secondary"
                                value={patientEmail}
                                fullWidth
                                disabled
                                onChange={handleEmailChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs>
                    {patientExists && (
                      <Grid
                        container
                        spacing={3}
                        direction="column"
                        className={classes.sections}
                      >
                        <Grid item xs style={{ paddingLeft: "0px" }}>
                          <button
                            className="primary_button"
                            onClick={() => {
                              setShowDialog(true);
                            }}
                          >
                            Browse Reports
                          </button>
                        </Grid>
                        {ReportListing}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </div>
              <div>
                <Divider className={classes.divider} />
                <Grid container className={classes.bottomNav}>
                  <Grid item xs={10}>
                    <button
                      className="btn_outline"
                      onClick={() => {
                        clearState();
                        setUploadReports(false);
                      }}
                    >
                      Back
                    </button>
                  </Grid>
                  <Grid item xs={2}>
                    {!addNotes && (
                      <button
                        className="primary_button"
                        onClick={async () => {
                          if (patientExists) {
                            clearState();
                            setUploadReports(false);
                            fetchPendingReports();
                          } else {
                            profileSearch();
                          }
                        }}
                      >
                        Save Reports
                      </button>
                    )}
                  </Grid>
                </Grid>
              </div>
              {showDialog && (
                <UploadReportDialog
                  closeFn={() => {
                    setShowDialog(false);
                    fetchPatientReports();
                  }}
                  patientId={patientId}
                  isFromAppointment={false}
                />
              )}
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spinner animation="border" role="status"></Spinner>
            </div>
          )}
        </div>
      ) : reportsLoaded ? (
        <div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              className="primary_button"
              onClick={() => {
                setUploadReports(true);
              }}
            >
              Add Reports
            </button>
          </div>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Sr. no.</TableCell>
                  <TableCell align="center">Patient ID</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingReports.map((profile: any, index: number) => (
                  <TableRow key={profile.patientID}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{profile.patientID}</TableCell>
                    <TableCell align="center">{profile.patientName}</TableCell>
                    <TableCell align="center">
                      <button
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          setID(profile.patientID.toString());
                          setUploadReports(true);
                          setAddNotes(true);
                        }}
                        className={`btn_outline`}
                        style={{ cursor: "pointer", display: "inline-block" }}
                      >
                        Add Note
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spinner animation="border" role="status"></Spinner>
        </div>
      )}
    </div>
  );
}
