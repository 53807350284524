import AppointmentResponse from "../models/serviceApi/AppointmentResponse";
import Appointment from "../models/domain/Appointment";
import AttendeeMetadataMapper from "./AttendeeMetadataMapper";

export default class AppointmentMapper {
  static mapResponseToModel(response: AppointmentResponse): Appointment {
    return new Appointment(
      response.id,
      response.date,
      response.startTime,
      response.appointmentStatus,
      response.attendeeType,
      response.attendeeStatus,
      response.payment,
      AttendeeMetadataMapper.mapResponseToModel(response.attendeeMetadata),
      response.ownerMetadata,
      response.spaceMetadata,
      response.consultation,
      response.source,
      response.online,
      response.queueNumber,
      response.vital,
      response.report
    );
  }
}
