import React from 'react';
import AlertComponent, { AlertSeverity } from './AlertComponent'
import { Snackbar } from '@material-ui/core'


interface SnackbarComponentProps {
    severity: AlertSeverity;
    message: string;
    onClose: () => void;
}

export default function SnackbarComponent({
    severity,
    message,
    onClose,
}: SnackbarComponentProps) {
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={true}
            onClose={onClose}
            autoHideDuration={6000}
        >
            <AlertComponent onClose={onClose} severity={severity}>
                {message}
            </AlertComponent>
        </Snackbar>
    );
}
