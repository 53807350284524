import AppointmentListResponse from '../models/serviceApi/AppointmentListResponse';
import AppointmentList from '../models/domain/AppointmentList'
import AppointmentMapper from './AppointmentMapper'

export default class AppointmentListMapper {
  static mapResponseToModel(response: AppointmentListResponse): AppointmentList {
    return new AppointmentList(
      response.appointments.map(AppointmentMapper.mapResponseToModel),
      response.spaceId,
      response.status,
    );
  }
}
