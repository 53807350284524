import React, { createContext, ReactNode, useContext, useState } from 'react';
import SnackbarComponent from '../components/common/SnackbarComponent'
import { AlertSeverity } from '../components/common/AlertComponent'

export interface SnackbarContext {
    setSnackbar: (snackbar: SnackbarInfo) => void;
}

interface SnackbarInfo {
    severity: AlertSeverity;
    message: string;
}

const SnackbarContext = createContext<SnackbarContext>({
    setSnackbar: () => {},
});

interface ProvideSnackbarContextProps {
    children: ReactNode;
}

export function ProvideSnackbarContext({
    children,
}: ProvideSnackbarContextProps): JSX.Element {
    const [snackbar, setSnackbar] = useState<SnackbarInfo | undefined>(
        undefined
    );

    return (
        <SnackbarContext.Provider value={{ setSnackbar }}>
            {children}
            {snackbar && (
                <SnackbarComponent
                    onClose={() => {
                        setSnackbar(undefined);
                    }}
                    severity={snackbar.severity}
                    message={snackbar.message}
                />
            )}
        </SnackbarContext.Provider>
    );
}

export function useSnackbarContext(): SnackbarContext {
    return useContext(SnackbarContext);
}
