import RemoteResource from "../remoteResource/RemoteResource";
import UpcomingUserAppointment from "../../models/domain/UpcomingUserAppointment";
import UpcomingUserWaitlist from "../../models/domain/UpcomingUserWaitlist";
import useUpcomingUserAppointment from "./useUpcomingUserAppointment";
import useUpcomingUserWaitlist from "./useUpcomingUserWaitlist";

class UpcomingUserVisit {
  constructor(
    public readonly upcomingAppointment: RemoteResource<UpcomingUserAppointment>,
    public readonly upcomingWaitlist: RemoteResource<UpcomingUserWaitlist>
  ) {}
}

export default function useUpcomingUserVisit(
  patientId: string,
  userProfile: any
): UpcomingUserVisit {
  const upcomingUserAppointment = useUpcomingUserAppointment(
    {
      attendeeId: patientId,
    },
    userProfile
  );
  const upcomingUserWaitlist = useUpcomingUserWaitlist({
    patientId: patientId,
  });

  return new UpcomingUserVisit(upcomingUserAppointment, upcomingUserWaitlist);
}
