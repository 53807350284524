export default class AttendeeMetadata {
  constructor(
    public readonly clinic_id: string,
    public readonly app_id: string,
    public readonly name: string,
    public readonly mobile: string,
    public readonly email: string,
  ) {
  }
}
