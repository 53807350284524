import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  TextField,
  Table,
  InputAdornment,
  Paper,
  Grid,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  Divider,
  OutlinedInput,
  TableContainer,
  IconButton,
  Chip,
  withStyles,
} from "@material-ui/core";
import PageWrapper from "../components/layout/PageWrapper";
import { SearchOutlined } from "@material-ui/icons";
import { useDebounce } from "../controllers/useDebounce";
import HttpClient from "../lib/HttpClient";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useSnackbarContext } from "../contexts/Snackbar";
import { AppointmentHistoryItem } from "../models/domain/AppointmentHistory";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: "#ffffff",
      alignItems: "center",
      justifyContent: "center",
      padding: 40,
      marginLeft: 40,
      marginRight: 40,
      marginTop: 100,
      marginBottom: 40,
      flex: 1,
      minHeight: 800,
    },
    input: {
      backgroundColor: "#ffffff",
      width: 400,
      height: 40,
    },
    smallInput: {
      backgroundColor: "#ffffff",
      width: 300,
      height: 40,
      marginTop: 10,
      paddingBottom: 10,
    },
    button: {
      color: "#F78F2D",
      borderColor: "#F78F2D",
    },
    table: {
      minWidth: 650,
    },
    tableSmall: {
      minWidth: 650,
      maxHeight: 220,
    },
    sectionPadding: {
      paddingTop: 20,
      paddingBottom: 10,
    },
    divider: {
      background: "#696969",
    },
    heading: {
      fontSize: 18,
    },
    label: {
      fontSize: 24,
      alignment: "right",
      paddingBottom: 20,
      fontWeight: 400,
    },
    smallLabel: {
      fontSize: 15,
      alignment: "right",
      padding: 10,
      fontWeight: 500,
    },
    detailPadding: {
      padding: 10,
      marginRight: 150,
    },
    pendingLabel: {
      backgroundColor: "#FCC280",
      color: "#FCC280",
    },
    emptyStateText: {
      marginTop: 50,
      marginBottom: 150,
      marginLeft: 150,
      fontWeight: 300,
      fontSize: 15,
    },
    detailTitle: {
      paddingTop: 10,
      paddingBottom: 18,
    },
    detailValue: {
      paddingTop: 10,
      paddingBottom: 18,
    },
  })
);

export default function PatientInfo() {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const finalQuery = useDebounce<string>(searchQuery, 500);
  const [patientResults, setPatientResults] = useState<any[]>([]);
  const [appointmentResults, setAppointmentResults] = useState<
    AppointmentHistoryItem[]
  >([]);
  const [reportResults, setReportResults] = useState<any[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [patient, setPatient] = useState<any>();
  const [editing, setEditing] = useState<boolean>(false);
  const [reportsPage, setReportsPage] = useState<number>(0);
  const [appointmentPage, setAppointmentPage] = useState<number>(0);
  const [reportsNext, setReportsNext] = useState<boolean>(false);
  const [appointmentNext, setAppointmentNext] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [address, setAddress] = useState<string>("");

  const [isInputValid, setIsInputValid] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  //const doctorProfile = utils.retrieveSelectDoctorProfile();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    patient.email = event.target.value;
  };
  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
    patient.address = event.target.value;
  };
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
    patient.mobile = event.target.value;
  };

  const { setSnackbar } = useSnackbarContext();

  const PendingChip = withStyles({
    root: {
      backgroundColor: "#FCD8B0",
      color: "#F0932A",
    },
  })(Chip);

  const CompletedChip = withStyles({
    root: {
      backgroundColor: "#B0FCB0",
      color: "#02B002",
    },
  })(Chip);

  const CancelledChip = withStyles({
    root: {
      backgroundColor: "#FFB4B4",
      color: "#C20606",
    },
  })(Chip);

  const StyledTableContainer = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&::-webkit-scrollbar": {
          width: 8,
        },

        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "grey",
          borderRadius: 5,
        },
        overflowX: "hidden",
      },
    })
  )(TableContainer);

  useEffect(() => {
    setIsInputValid(phone !== "" && firstName !== "");
  }, [phone, firstName]);

  useEffect(() => {
    if (finalQuery && finalQuery?.trim().length >= 2) {
      setIsSearching(true);
      HttpClient.performGet<any>(
        `/api/v1/users/search?query=${finalQuery?.trim()}`
      )
        .then(({ body }) => {
          if (body?.profiles && body.profiles.length > 0) {
            setPatientResults(body?.profiles);
            setIsSearching(false);
          } else {
            setPatientResults([]);
            setIsSearching(false);
          }
        })
        .catch(() => {
          setPatientResults([]);
          setIsSearching(false);
        });
    } else {
      setPatientResults([]);
    }
  }, [finalQuery]);

  function fetchPatientDetails(patientID: string) {
    HttpClient.performGet<any>(`/api/v1/users/search?patientId=${patientID}`)
      .then(({ body }) => {
        if (body?.profiles && body.profiles.length > 0) {
          setPatient(body.profiles[0]);
          setFirstName(body.profiles[0].firstName);
          setMiddleName(body.profiles[0].middleName);
          setLastName(body.profiles[0].lastName);
          setEmail(body.profiles[0].email);
          setAddress(body.profiles[0].address);
          setPhone(body.profiles[0].mobile);
          getAppointmentHistory(
            body.profiles[0]?.appId
              ? body.profiles[0]?.appId
              : body.profiles[0]?.clinicPatientId,
            appointmentPage
          );
          if (body.profiles[0]?.appId) {
            getReportHistory(body.profiles[0].appId, reportsPage);
          }
        } else {
          setSnackbar({
            message: "Error fetching patient details",
            severity: "error",
          });
        }
      })
      .catch(() => {});
  }

  function editPatientDetails() {
    HttpClient.performPatch<any>(`/api/v1/users/${patient.id}`, {
      mobile: phone,
      email: email,
      address: address,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
    })
      .then((response) => {
        if (!response?.body?.status?.success) {
          setSnackbar({
            message: response?.body?.status?.message,
            severity: "error",
          });
        } else {
          patient.email = email;
          patient.address = address;
          patient.mobile = phone;
          patient.name = firstName + " " + middleName + " " + lastName;
          setEditing(false);
          setSnackbar({
            message: "Patient details updated successfully!",
            severity: "success",
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function getAppointmentHistory(patientID: string, page: number) {
    HttpClient.performGet<any>(
      `/api/v1/users/history/appointments/${patientID}?p=${page}`
    )
      .then(({ body }) => {
        if (body?.appointments && body.appointments.length > 0) {
          let appointmentHistory: AppointmentHistoryItem[] = [];
          if (body?.appointments) {
            appointmentHistory = body!!
              .appointments as AppointmentHistoryItem[];
          }
          setAppointmentResults(appointmentHistory);
          setAppointmentNext(body?.next);
        } else {
          setAppointmentResults([]);
          setAppointmentNext(false);
        }
      })
      .catch(() => {
        setAppointmentResults([]);
        setAppointmentNext(false);
      });
  }
  function getReportHistory(patientID: string, page: number) {
    HttpClient.performGet<any>(
      `/api/v1/users/history/reports/${patientID}?p=${page}`
    )
      .then(({ body }) => {
        if (body?.reports && body.reports.length > 0) {
          setReportResults(body?.reports);
          setReportsNext(body?.next);
        } else {
          setReportResults([]);
          setReportsNext(false);
        }
      })
      .catch(() => {
        setReportResults([]);
        setReportsNext(false);
      });
  }

  return (
    <PageWrapper>
      <Paper className={classes.paper}>
        {patient == null ? (
          <Grid container direction="column">
            <Grid item>
              <TextField
                className={classes.input}
                id="outlined-select-currency-native"
                variant="outlined"
                size="small"
                placeholder="Search Patient by Name/ID/Mobile number"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined color="disabled" />
                    </InputAdornment>
                  ),
                }}></TextField>
            </Grid>
            {patientResults.length > 0 && (
              <Grid item className={classes.sectionPadding}>
                <Divider className={classes.divider} />
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className={classes.heading}>
                        Patient Name
                      </TableCell>
                      <TableCell align="center" className={classes.heading}>
                        Patient ID
                      </TableCell>
                      <TableCell align="center" className={classes.heading}>
                        Mobile Number
                      </TableCell>
                      <TableCell align="center" className={classes.heading}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {patientResults.map((row) => (
                      <TableRow
                        key={row.appId ? row.appId : row.clinicPatientId}>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">
                          {row.appId ? row.appId : row.clinicPatientId}
                        </TableCell>
                        <TableCell align="center">{row.mobile}</TableCell>
                        <TableCell align="center">
                          <Button
                            size="small"
                            variant="outlined"
                            className={classes.button}
                            onClick={() => {
                              fetchPatientDetails(
                                row.appId ? row.appId : row.clinicPatientId
                              );
                            }}>
                            View Profile
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid container direction="column">
            <Grid
              item
              container
              direction="row"
              style={{ justifyContent: "space-between" }}>
              <Grid item>
                <Button
                  className={classes.button}
                  variant="outlined"
                  onClick={() => {
                    setPatient(null);
                    setAppointmentResults([]);
                    setReportResults([]);
                    setAppointmentPage(0);
                    setReportsPage(0);
                  }}>
                  Back
                </Button>
              </Grid>
              <Grid item>
                {editing ? (
                  <Button
                    className={classes.button}
                    variant="outlined"
                    disabled={!isInputValid}
                    onClick={() => {
                      editPatientDetails();
                    }}>
                    Save Changes
                  </Button>
                ) : (
                  <Button
                    className={classes.button}
                    variant="outlined"
                    onClick={() => {
                      setEditing(true);
                    }}>
                    Edit Profile
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              style={{ justifyContent: "space-between", padding: 30 }}>
              <Grid item xs={12} md={4} xl={4}>
                <Grid container direction="column">
                  <Grid item className={classes.detailPadding}>
                    <span className={classes.label}>Patient Details</span>
                  </Grid>

                  <Grid item container direction="row">
                    <Grid
                      item
                      xs={4}
                      md={4}
                      xl={5}
                      container
                      direction="column">
                      <Grid item className={classes.detailTitle}>
                        <span className={classes.smallLabel}>Patient ID</span>
                      </Grid>
                      {!editing ? (
                        <Grid item className={classes.detailTitle}>
                          <span className={classes.smallLabel}>
                            Patient Name
                          </span>
                        </Grid>
                      ) : (
                        <>
                          <Grid item className={classes.detailTitle}>
                            <span className={classes.smallLabel}>
                              First name
                            </span>
                          </Grid>
                          <Grid item className={classes.detailTitle}>
                            <span className={classes.smallLabel}>
                              Middle name
                            </span>
                          </Grid>
                          <Grid item className={classes.detailTitle}>
                            <span className={classes.smallLabel}>
                              Last name
                            </span>
                          </Grid>
                        </>
                      )}

                      <Grid item className={classes.detailTitle}>
                        <span className={classes.smallLabel}>Mobile No</span>
                      </Grid>
                      <Grid item className={classes.detailTitle}>
                        <span className={classes.smallLabel}>Email ID</span>
                      </Grid>
                      <Grid item className={classes.detailTitle}>
                        <span className={classes.smallLabel}>Address</span>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      md={5}
                      xl={6}
                      container
                      direction="column">
                      <Grid item className={classes.detailValue}>
                        <span>
                          {patient.appId
                            ? patient.appId
                            : patient.clinicPatientId}
                        </span>
                      </Grid>

                      {!editing ? (
                        <Grid item className={classes.detailValue}>
                          <span>{patient.name || <span>&nbsp;</span>}</span>
                        </Grid>
                      ) : (
                        <>
                          <Grid item>
                            <OutlinedInput
                              className={classes.smallInput}
                              id="firstName"
                              key="firstName"
                              value={firstName}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => setFirstName(event.target.value)}
                            />
                          </Grid>

                          <Grid item>
                            <OutlinedInput
                              className={classes.smallInput}
                              id="middleName"
                              key="middleName"
                              value={middleName}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => setMiddleName(event.target.value)}
                            />
                          </Grid>

                          <Grid item>
                            <OutlinedInput
                              className={classes.smallInput}
                              id="lastName"
                              key="lastName"
                              value={lastName}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => setLastName(event.target.value)}
                            />
                          </Grid>
                        </>
                      )}

                      {!editing ? (
                        <Grid item className={classes.detailValue}>
                          <span>{patient.mobile || <span>&nbsp;</span>}</span>
                        </Grid>
                      ) : (
                        <Grid item>
                          <OutlinedInput
                            className={classes.smallInput}
                            id="mobile"
                            key="mobile"
                            value={phone}
                            onChange={handlePhoneChange}
                          />
                        </Grid>
                      )}

                      {!editing ? (
                        <Grid item className={classes.detailValue}>
                          <span>{patient.email || <span>&nbsp;</span>}</span>
                        </Grid>
                      ) : (
                        <Grid item>
                          <OutlinedInput
                            className={classes.smallInput}
                            id="email"
                            key="email"
                            value={email}
                            onChange={handleEmailChange}
                          />
                        </Grid>
                      )}

                      {!editing ? (
                        <Grid item className={classes.detailValue}>
                          <span>{address || <span>&nbsp;</span>}</span>
                        </Grid>
                      ) : (
                        <Grid item>
                          <OutlinedInput
                            className={classes.smallInput}
                            id="address"
                            key="address"
                            value={address}
                            onChange={handleAddressChange}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                md={1}
                xl={1}
                container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Divider
                  orientation="vertical"
                  className={classes.divider}
                  style={{ height: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={7} xl={7}>
                <Grid container direction="column">
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <span className={classes.label}>
                          Appointment History
                        </span>
                      </Grid>
                      {appointmentResults.length > 0 ? (
                        <Grid item className={classes.sectionPadding}>
                          <StyledTableContainer className={classes.tableSmall}>
                            <Table
                              className={classes.table}
                              aria-label="simple table"
                              stickyHeader>
                              <colgroup>
                                <col width="30%" />
                                <col width="40%" />
                                <col width="20%" />
                              </colgroup>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    className={classes.heading}>
                                    Date
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.heading}>
                                    Appointment With
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.heading}>
                                    Status
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {appointmentResults.map((row) => (
                                  <TableRow
                                    key={appointmentResults.indexOf(row)}>
                                    <TableCell align="center">
                                      {row.date}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.healthProviderMetaData
                                        ?.healthProviderName
                                        ? row.healthProviderMetaData!
                                            .healthProviderName
                                        : row.appointmentWith}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{ padding: 0 }}>
                                      {row.status === "Scheduled" ||
                                      row.status === "Rescheduled" ? (
                                        <PendingChip
                                          label="Pending"
                                          className="classes.pendingLabel"
                                        />
                                      ) : row.status === "Cancelled" ? (
                                        <CancelledChip
                                          label="Cancelled"
                                          className="classes.pendingLabel"
                                        />
                                      ) : row.status === "Completed" ? (
                                        <CompletedChip
                                          label="Completed"
                                          className="classes.pendingLabel"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </StyledTableContainer>
                        </Grid>
                      ) : (
                        <span className={classes.emptyStateText}>
                          No appointments booked
                        </span>
                      )}
                      {appointmentResults.length > 0 && (
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            alignItems="flex-end"
                            justifyContent="flex-end">
                            <Grid item>
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                disabled={appointmentPage === 0}
                                onClick={() => {
                                  getAppointmentHistory(
                                    patient.appId
                                      ? patient.appId
                                      : patient.clinicPatientId,
                                    appointmentPage - 1
                                  );
                                  setAppointmentPage(appointmentPage - 1);
                                }}>
                                <ArrowBackIosIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                disabled={!appointmentNext}
                                onClick={() => {
                                  getAppointmentHistory(
                                    patient.appId
                                      ? patient.appId
                                      : patient.clinicPatientId,
                                    appointmentPage + 1
                                  );
                                  setAppointmentPage(appointmentPage + 1);
                                }}>
                                <ArrowForwardIosIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider className={classes.divider}></Divider>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      className={classes.sectionPadding}>
                      <Grid item>
                        <span className={classes.label}>
                          Report Sent History
                        </span>
                      </Grid>
                      {reportResults.length > 0 ? (
                        <Grid item className={classes.sectionPadding}>
                          <StyledTableContainer className={classes.tableSmall}>
                            <Table
                              stickyHeader
                              className={classes.table}
                              aria-label="simple table">
                              <colgroup>
                                <col width="30%" />
                                <col width="60%" />
                              </colgroup>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    className={classes.heading}>
                                    Date
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.heading}>
                                    Report Name
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {reportResults.map((row) => (
                                  <TableRow key={reportResults.indexOf(row)}>
                                    <TableCell align="center">
                                      {row.date}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.name}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </StyledTableContainer>
                        </Grid>
                      ) : (
                        <span className={classes.emptyStateText}>
                          No reports available
                        </span>
                      )}
                      {reportResults.length > 0 && (
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            alignItems="flex-end"
                            justifyContent="flex-end">
                            <Grid item>
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                disabled={reportsPage === 0}
                                onClick={() => {
                                  getReportHistory(
                                    patient.appId,
                                    reportsPage - 1
                                  );
                                  setReportsPage(reportsPage - 1);
                                }}>
                                <ArrowBackIosIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                disabled={!reportsNext}
                                onClick={() => {
                                  getReportHistory(
                                    patient.appId,
                                    reportsPage + 1
                                  );
                                  setReportsPage(reportsPage + 1);
                                }}>
                                <ArrowForwardIosIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider className={classes.divider}></Divider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Paper>
    </PageWrapper>
  );
}
