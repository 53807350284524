import Status from './Status'
import Appointment from './Appointment'

export default class AppointmentList {
  constructor(
    public readonly appointments: Appointment[],
    public readonly space_id: string,
    public readonly status: Status,
  ) {
  }
}
