export default class HttpClient {
  static async performGet<T>(url: string): Promise<HttpGetOutput<T>> {
    const response = await fetch(url, {
      method: "GET",
      headers: HttpClient.createHeaders(),
    });
    const responseBody = await HttpClient.getResponseBodyAsJson(response);

    HttpClient.throwErrorIfNeeded(
      "GET",
      response.url,
      response.status,
      responseBody
    );

    return {
      body: responseBody,
      headers: response.headers,
    };
  }

  static async performPut<T>(
    url: string,
    data?: unknown
  ): Promise<HttpGetOutput<T>> {
    const response = await fetch(
      url,
      data
        ? {
            method: "PUT",
            headers: HttpClient.createHeaders({
              "Content-type": "application/json",
            }),

            body: JSON.stringify(data),
          }
        : {
            method: "PUT",
            headers: HttpClient.createHeaders({
              "Content-type": "application/json",
            }),
          }
    );
    const responseBody = await HttpClient.getResponseBodyAsJson(response);

    HttpClient.throwErrorIfNeeded(
      "PUT",
      response.url,
      response.status,
      responseBody
    );

    return {
      body: responseBody,
      headers: response.headers,
    };
  }

  private static async getResponseBodyAsJson(response: Response) {
    const contentType = response.headers?.get("content-type");
    if (contentType && contentType.startsWith("application/json")) {
      return await response.json();
    } else {
      return {};
    }
  }

  static async performPost<T>(
    url: string,
    data: unknown
  ): Promise<HttpMutationOutput<T>> {
    const response = await fetch(url, {
      method: "POST",
      headers: HttpClient.createHeaders({
        "Content-type": "application/json",
      }),
      body: JSON.stringify(data),
    });
    const responseBody = await HttpClient.getResponseBodyAsJson(response);

    HttpClient.throwErrorIfNeeded(
      "POST",
      response.url,
      response.status,
      responseBody
    );
    return {
      body: responseBody,
      status: response.status,
    };
  }

  static async performPatch<T>(
    url: string,
    data: unknown
  ): Promise<HttpMutationOutput<T>> {
    const response = await fetch(url, {
      method: "PATCH",
      headers: HttpClient.createHeaders({
        "Content-type": "application/json",
      }),
      body: JSON.stringify(data),
    });
    const responseBody = await HttpClient.getResponseBodyAsJson(response);

    HttpClient.throwErrorIfNeeded(
      "PATCH",
      response.url,
      response.status,
      responseBody
    );

    return {
      body: responseBody,
      status: response.status,
    };
  }

  private static createHeaders(
    extraHeaders?: Record<string, string>
  ): HeadersInit {
    const token = localStorage.getItem("token");

    if (token) {
      return {
        authorization: `Bearer ${token}`,
        ...extraHeaders,
      };
    }

    return { ...extraHeaders };
  }

  private static throwErrorIfNeeded(
    method: string,
    url: string,
    status: number,
    body: { message: string; [key: string]: any }
  ) {
    if (status === 401) {
      localStorage.clear();
      //   window.location.href = "/";
      throw new UnauthenticatedError(url, method, body);
    }

    if (method === "GET" && (status === 400 || status === 404)) {
      throw new PageNotFoundError(url, method, body);
    }
  }
}

export interface HttpGetOutput<T> {
  body: T;
  headers: Headers;
}

export interface HttpMutationOutput<T> {
  body: T;
  status: number;
}

export class HttpError extends Error {
  constructor(public readonly message: string, public readonly body?: unknown) {
    super(message);
  }
}

export class PageNotFoundError extends HttpError {
  constructor(
    url: string,
    method: string,
    body: { message: string; [key: string]: any }
  ) {
    super(`Page not found for ${method} ${url}`, body);
  }
}

export class UnauthenticatedError extends HttpError {
  constructor(
    url: string,
    method: string,
    body: { message: string; [key: string]: any }
  ) {
    super(`The server returned a 401 status code for ${method} ${url}`, body);
  }
}
