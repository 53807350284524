import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import ReceptionistProfile from "../../models/domain/ReceptionistProfile";
import utils from "../../Utils";
import HttpClient from "../../lib/HttpClient";
import { useSnackbarContext } from "../../contexts/Snackbar";
import Appointment from "../../models/domain/Appointment";

interface TransferPatientModalProps {
  isTransferPatientModalOpen: boolean | undefined;
  setIsTransferPatientModalOpen: (isTransferPatientModalOpen: boolean) => void;
  selectedAppointmentForTransfer: any;
  setSelectedAppointmentForTransfer: (appointment: object | undefined) => void;
}

// This function gives list of doctor options for transferring Appointment
// If the appointment is associated to a jr Doctor, give option to transfer to sr doctor ie Dr Akerkar
const getClinicDoctors = (
  userProfile: ReceptionistProfile,
  selectedAppointmentForTransfer: any
) => {
  if (selectedAppointmentForTransfer?.owner_metadata.code !== "DR000001") {
    return userProfile?.associatedHealthCareProviders.filter(
      (healthProvider) =>
        healthProvider.providerType === "Doctor" &&
        healthProvider.id == "DR000001"
    );
  }
  return userProfile?.associatedHealthCareProviders.filter(
    (healthProvider) =>
      healthProvider.providerType === "Doctor" &&
      healthProvider.id !== selectedAppointmentForTransfer?.owner_metadata.code
  );
};

const requestTransferPatientToSelectedDoctor = (
  selectedAppointmentForTransfer: any,
  selectedDoctor: string
) =>
  HttpClient.performPut<any>(
    `/api/v1/users/transfer/${selectedAppointmentForTransfer.id}/${selectedDoctor}`
  );

const TransferPatientModal = ({
  isTransferPatientModalOpen,
  setIsTransferPatientModalOpen,
  selectedAppointmentForTransfer,
  setSelectedAppointmentForTransfer,
}: TransferPatientModalProps) => {
  const [userProfile, setUserProfile] = useState<ReceptionistProfile>(
    utils.emptyUserProfile
  );

  const [selectedDoctor, setSelectedDoctor] = React.useState<
    string | undefined
  >("");

  const { setSnackbar } = useSnackbarContext();
  useEffect(() => {
    const _profile = utils.retrieveUserProfile();
    if (_profile) {
      setUserProfile(_profile);
      const defaultSelectedDoctorForTransfer = getClinicDoctors(
        _profile,
        selectedAppointmentForTransfer
      )[0]?.id;
      defaultSelectedDoctorForTransfer &&
        setSelectedDoctor(defaultSelectedDoctorForTransfer);
    }
  }, []);

  const transferPatient = async (
    selectedAppointmentForTransfer: any,
    selectedDoctor: string
  ) => {
    try {
      const response = await requestTransferPatientToSelectedDoctor(
        selectedAppointmentForTransfer,
        selectedDoctor!!
      );
      if (response?.body?.status?.code == "200") {
        setSelectedAppointmentForTransfer(undefined);
        setIsTransferPatientModalOpen(false);
        setSnackbar({
          message: "Appointment transfer successful!",
          severity: "success",
        });
      } else {
        setSnackbar({
          message: response?.body?.status?.message,
          severity: "error",
        });
      }
    } catch (e) {
      setSnackbar({
        message: "Something went wrong!",
        severity: "error",
      });
    }
  };

  const cancelTransferAndCloseModal = () => {
    setSelectedAppointmentForTransfer(undefined);
    setIsTransferPatientModalOpen(false);
  };

  return (
    <Modal
      show={isTransferPatientModalOpen}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      {console.log(selectedAppointmentForTransfer)}
      <Modal.Header closeButton onClick={cancelTransferAndCloseModal}>
        <Modal.Title>Transfer Patient</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex">
          <div>
            <span className="fw-bold">Patient Name </span>:{" "}
            {selectedAppointmentForTransfer?.attendee_metadata.name}
          </div>
          <div className="mx-4">
            <span className="fw-bold">Patient ID </span>:{" "}
            {selectedAppointmentForTransfer?.attendee_metadata.app_id}
          </div>
        </div>
        <div className="mt-4">
          <FormControl component="fieldset">
            {/* <FormLabel component="legend"><h5>Select</h5></FormLabel> */}
            <h5>Select Doctor</h5>
            <RadioGroup
              aria-label="doctors"
              name="transfer-doctor"
              value={selectedDoctor}
              onChange={(event) => setSelectedDoctor(event.target.value)}>
              {getClinicDoctors(
                userProfile,
                selectedAppointmentForTransfer
              ).map((doctor) => (
                <FormControlLabel
                  value={doctor.id}
                  control={<Radio />}
                  label={doctor.name}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn_outline" onClick={cancelTransferAndCloseModal}>
          Cancel
        </button>
        <button
          className="btn_primary"
          onClick={() => {
            transferPatient(selectedAppointmentForTransfer, selectedDoctor!!);
          }}>
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default TransferPatientModal;
