import ProfileResponse from '../models/serviceApi/ProfileResponse';
import Profile from '../models/domain/Profile';

export default class ProfileMapper {
  static mapResponseToModel (response: ProfileResponse): Profile {
    return new Profile(
        response.appId,
        response.clinicPatientId,
        response.email,
        response.mobile,
        response.name,
        response.doctorId
    );
  }
}
