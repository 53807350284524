import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import useAppointmentList from "../../controllers/appointments/useAppointmentList";
import { Alert } from "@material-ui/lab";
import CurrentSessionStatsWidget from "./CurrentSessionStatsWidget";
import appointmentListStats from "../../controllers/appointments/appointmentListStats";
import AppointmentInfo from "../../models/domain/AppointmentInfo";
import CancelAppointmentDialog from "./CancelAppointmentDialog";
import utils from "../../Utils";
import HttpClient from "../../lib/HttpClient";
import { useSnackbarContext } from "../../contexts/Snackbar";
import CompleteAppointmentDialog from "./CompleteAppointmentDialog";
import PatientInformationDialog from "./PatientInformationDialog";
import ConfirmPatientArriveDialog from "./ConfirmPatientArriveDialog";
import Appointment from "../../models/domain/Appointment";
import { FaPrint } from "react-icons/fa";
import WaitingListDialog from "./WaitingListDialog";
import { FormControl, Input, InputAdornment } from "@material-ui/core";
import { MdOutlineSearch } from "react-icons/md";
import ReceptionistProfile from "../../models/domain/ReceptionistProfile";
import HealthCareProviderProfile from "../../models/domain/HealthCareProviderProfile";
import { Spinner } from "react-bootstrap";
import { ReactComponent as TransferIcon } from "../../img/icons/transfer-icon.svg";
import { ReactComponent as TransferIconDisabled } from "../../img/icons/transfer-icon-disabled.svg";
import TransferPatientModal from "./TransferPatientModal";
import { IoVideocam } from "react-icons/io5";
import AddVitalsModal from "./AddVitalsModal";
import UploadReportDialog from "../reports/UploadReportDialog";

export default function TodayAppointmentTable() {
  // Get profile from local storage during Init
  const [userProfile, setUserProfile] = useState<ReceptionistProfile>(
    utils.emptyUserProfile
  );
  const [doctorProfile, setDoctorProfile] = useState<HealthCareProviderProfile>(
    utils.retrieveSelectDoctorProfile()
  );
  useEffect(() => {
    const _profile = utils.retrieveUserProfile();
    if (_profile) {
      setUserProfile(_profile);
    }
  }, []);

  const { setSnackbar } = useSnackbarContext();
  const today = new Date().toISOString().split("T")[0];
  const todayAppointments = useAppointmentList({
    date: today,
    ownerId: doctorProfile.id,
  });
  const [isCancelDialogVisible, setCancelDialogVisibility] =
    React.useState(false);
  const [isCompleteDialogVisible, setCompleteDialogVisibility] =
    React.useState(false);
  const [isConfrmDialogVisible, setConfirmDialogVisibility] =
    React.useState(false);
  const [
    isPatientInformationDialogVisible,
    setIsPatientInformationDialogVisible,
  ] = React.useState(false);
  const [isWaitingListVisible, setIsWaitingListVisible] = React.useState(false);
  const [selectedAppointment, setSelectedAppointment] =
    React.useState<AppointmentInfo>({
      id: "",
      patientName: "",
    });
  const [appointment, setAppointment] = React.useState<Appointment>();
  const print = () => window.print();

  const [isTransferPatientModalOpen, setIsTransferPatientModalOpen] =
    React.useState<boolean>(false);
  const [selectedAppointmentForTransfer, setSelectedAppointmentForTransfer] =
    React.useState<object | undefined>(undefined);

  const [isAddVitalsModalOpen, setIsAddVitalsModalOpen] =
    React.useState<boolean>(false);
  const [selectedAppointmentForVitals, setSelectedAppointmentForVitals] =
    React.useState<object | undefined>(undefined);
  const [selectedAppointmentForReports, setSelectedAppointmentForReports] =
    React.useState<Appointment | undefined>(undefined);

  const [isReportUploadDailogOpen, setIsReportUploadDailogOpen] =
    React.useState<boolean>(false);

  const selectPatientForTransfer = (appointment: object) => {
    setIsTransferPatientModalOpen(true);
    setSelectedAppointmentForTransfer(appointment);
  };

  const selectAppointmentForVitalsUpdate = (appointment: object) => {
    setIsAddVitalsModalOpen(true);
    setSelectedAppointmentForVitals(appointment);
  };

  const selectAppointmentForReportUpload = (appointment: Appointment) => {
    setIsReportUploadDailogOpen(true);
    setSelectedAppointmentForReports(appointment);
  };

  const openCancelDialog = (
    event: React.MouseEvent<HTMLElement>,
    appointment: AppointmentInfo
  ) => {
    event.preventDefault();
    setSelectedAppointment(appointment);
    setCancelDialogVisibility(true);
  };
  const openConfirmDialog = (
    event: React.MouseEvent<HTMLElement>,
    appointment: AppointmentInfo
  ) => {
    event.preventDefault();
    setSelectedAppointment(appointment);
    setConfirmDialogVisibility(true);
  };
  const openCompleteDialog = (
    event: React.MouseEvent<HTMLElement>,
    appointment: AppointmentInfo
  ) => {
    event.preventDefault();
    setSelectedAppointment(appointment);
    setCompleteDialogVisibility(true);
  };
  const [searchName, setSearchName] = React.useState("");

  useEffect(() => {
    const pollInterval = setInterval(() => todayAppointments.refresh(), 3000);

    return () => clearInterval(pollInterval);
  }, []);

  const updateAttendeeStatus = (id: string, status: string) => {
    let currentStatus = status;
    let newStatus = "ARRIVE";
    if (currentStatus === "ARRIVE") {
      newStatus = "ARRIVED";
    }

    HttpClient.performPut<any>(
      `/api/v1/appointments/${id}/status?attendeeStatus=${newStatus}`
    )
      .then((response) => {
        console.log("response", response);
        if (!response?.body?.status?.success) {
          setSnackbar({
            message: response?.body?.status?.message,
            severity: "error",
          });
        } else {
          setSnackbar({
            message: "Appointment status update successful!",
            severity: "success",
          });
          todayAppointments.refresh();
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        console.log("finally!");
      });
  };

  const selectDoctor = (doctor: HealthCareProviderProfile) => {
    localStorage.setItem("selectedDoctor", JSON.stringify(doctor));

    setDoctorProfile(doctor);
  };

  const closeCancelDialog = (toRefresh: boolean = false) => {
    if (toRefresh) {
      const _ = todayAppointments.refresh();
    }
    setCancelDialogVisibility(false);
  };

  const closeCompleteDialog = (toRefresh: boolean = false) => {
    if (toRefresh) {
      const _ = todayAppointments.refresh();
    }
    setCompleteDialogVisibility(false);
  };

  const closeConfirmDialog = (toRefresh: boolean = false) => {
    if (toRefresh) {
      const _ = todayAppointments.refresh();
    }
    setConfirmDialogVisibility(false);
  };

  const closeReportsDialog = (toRefresh: boolean = false) => {
    if (toRefresh) {
      const _ = todayAppointments.refresh();
    }
    setIsReportUploadDailogOpen(false);
    setSelectedAppointmentForReports(undefined);
  };

  const PatientArrivedComponent = (state: any) => {
    const appointment = state.appointment;
    //console.log(appointment);
    if (
      appointment.payment ||
      appointment.consultation ||
      appointment.appointment_status == "Completed"
    ) {
      return (
        <a
          className="link_readiness btn disabled"
          role="button"
          aria-disabled="true"
          style={{ cursor: "pointer" }}
        >
          Patient Arrived
        </a>
      );
    } else if (appointment.attendee_status == "Arrived") {
      return (
        <span
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            openConfirmDialog(e, {
              id: appointment.id,
              patientName: appointment.attendee_metadata.name,
            });
          }}
          className="link_arrived"
          style={{ cursor: "pointer" }}
        >
          Patient Arrived
        </span>
      );
    } else {
      return (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            updateAttendeeStatus(
              appointment.id,
              appointment.attendee_status.toUpperCase()
            );
          }}
          className="link_arrive"
        >
          Patient Arrive
        </span>
      );
    }
  };

  if (todayAppointments.isLoading) {
    return (
      <>
        <div className="main_content">
          <div className="container-fluid">
            <div className="section_title_block d-flex align-items-center justify-content-between">
              <h2 className="head_title_block">
                Today's Appointment (
                <span id="current_date">
                  {format(new Date(), "dd-MM-yyyy")}
                </span>
                )
              </h2>
              <h2 className="head_title_block">{`${userProfile.clinicName} - ${doctorProfile.name}`}</h2>
            </div>
            <div className="section_doctors">
              <ul className="d-flex">
                {userProfile.associatedHealthCareProviders.map((doctor) => (
                  <li
                    key={doctor.id}
                    onClick={() => setDoctorProfile(doctor)}
                    className={doctor.id == doctorProfile.id ? "active" : ""}
                  >
                    <div className="doctor_name">{doctor.name}</div>
                    <div className="session_timings">
                      {doctor?.sessionDetails}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="loading_screen">
            <Spinner />
          </div>
        </div>
      </>
    );
  }

  if (todayAppointments.error) {
    return (
      <Alert severity="error">{JSON.stringify(todayAppointments.error)}</Alert>
    );
  }

  return (
    <>
      <div className="main_content">
        <div className="container-fluid">
          <div className="section_title_block d-flex align-items-center justify-content-between">
            <h2 className="head_title_block">
              Today's Appointment (
              <span id="current_date">{format(new Date(), "dd-MM-yyyy")}</span>)
            </h2>
            <h2 className="head_title_block">{`${userProfile.clinicName} - ${doctorProfile.name}`}</h2>
          </div>
          <div className="section_doctors">
            <ul className="d-flex">
              {userProfile.associatedHealthCareProviders
                .filter((hcProfile) => hcProfile.providerType === "Doctor")
                .map((doctor) => (
                  <li
                    key={doctor.id}
                    onClick={() => selectDoctor(doctor)}
                    className={doctor.id == doctorProfile.id ? "active" : ""}
                  >
                    <div className="doctor_name">{doctor.name}</div>
                    <div className="session_timings">
                      {doctor?.sessionDetails}
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          <div className="inner_content p-0">
            <div className="appointment_block">
              <div className="appointment_head d-print-none">
                <div className="row d-flex align-items-center">
                  <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-10 gridcol">
                    <CurrentSessionStatsWidget
                      stats={appointmentListStats(todayAppointments.resource)}
                    />
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-2 gridcol d-print-none">
                    <div className="appointment_head_right">
                      <ul className="list-unstyled list_app_his_upcoming">
                        <li>
                          <Link to="/history-appointment" className="link_app">
                            Appointment - History
                          </Link>
                        </li>
                        <li>
                          <Link to="/upcoming-appointment" className="link_app">
                            Appointment - Upcoming
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="printer_block d-flex justify-content-start justify-content-between align-items-center d-print-none">
                <FormControl style={{ width: 400 }}>
                  <Input
                    placeholder={"Search Patient on this page by name"}
                    autoComplete={"off"}
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <MdOutlineSearch />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <div>
                  <a
                    style={{
                      backgroundColor: "#0060A6",
                      color: "#FFF",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setIsWaitingListVisible(true);
                    }}
                    className={"me-2 link_printer p-1 px-2"}
                  >
                    Waiting List
                  </a>
                  <a href="#" className="link_printer" onClick={print}>
                    <FaPrint className={"me-1"} />
                    <span className="txt">Print Appointment</span>
                  </a>
                </div>
              </div>
              <div className="appointment_body">
                <div className="table-responsive">
                  <table className="custom_table table">
                    <thead>
                      <tr>
                        {userProfile.healthCareProviderType === "Doctor" && (
                          <th>
                            <span className="txt fw-bold">Queue No</span>
                          </th>
                        )}

                        <th>
                          <span className="txt fw-bold">Time</span>
                        </th>
                        <th className="px-1"></th>
                        <th>
                          <span className="txt fw-bold">Patient Name</span>
                        </th>
                        <th>
                          <span className="txt fw-bold">Type</span>
                        </th>
                        <th className="text-center th_last_col">
                          <span className="txt fw-bold">
                            Readiness for Doctor
                          </span>
                        </th>
                        <th className="text-center th_last_col">
                          <span className="txt fw-bold">Appointment</span>
                        </th>

                        {userProfile.healthCareProviderType === "Doctor" && (
                          <th className="text-center th_last_col">
                            <span className="txt fw-bold">Transfer</span>
                          </th>
                        )}

                        <th className="text-center th_last_col">
                          <span className="txt fw-bold">Payment</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {todayAppointments.resource?.appointments
                        ?.filter((appointment) => {
                          return (
                            appointment.attendee_metadata.name
                              .toLowerCase()
                              .includes(searchName.trim().toLowerCase()) &&
                            appointment.source != "DOCTOR"
                          );
                        })
                        .map((appointment) => {
                          return (
                            <tr
                              // enabling this highlights new appointment coming from website
                              // className={
                              //   appointment.attendee_type == "New" &&
                              //   appointment.source == "WEB"
                              //     ? "highlighted_appointment"
                              //     : ""
                              // }
                              key={appointment.id}
                            >
                              {userProfile.healthCareProviderType ===
                                "Doctor" && (
                                <td>
                                  <span className="txt text-center px-4">
                                    {appointment.queueNumber || ""}
                                  </span>
                                </td>
                              )}
                              <td>
                                <span className="txt">
                                  {appointment.start_time}
                                </span>
                              </td>
                              <td className="px-1">
                                {appointment.isOnline && <IoVideocam />}
                              </td>
                              <td>
                                <span
                                  className="txt txt_cursor"
                                  onClick={() => {
                                    setAppointment(appointment);
                                    setIsPatientInformationDialogVisible(true);
                                  }}
                                >
                                  {appointment.attendee_metadata.name}
                                </span>
                              </td>
                              <td>
                                <span className="txt">
                                  {appointment.attendee_type}
                                </span>
                              </td>
                              <td
                                className="text-center td_last_col"
                                style={{
                                  pointerEvents:
                                    appointment.appointment_status ===
                                    "Completed"
                                      ? "none"
                                      : "initial",
                                }}
                              >
                                <div className="arrived_readiness_status_block d-flex align-items-center justify-content-center">
                                  <PatientArrivedComponent
                                    appointment={appointment}
                                  />
                                  <a
                                    className={`link_vitals ${
                                      appointment.vital && "link_vitals_success"
                                    }  ${
                                      appointment.appointment_status ===
                                        "Completed" && "disabled"
                                    } btn `}
                                    role="button"
                                    // aria-disabled="true"
                                    onClick={() =>
                                      selectAppointmentForVitalsUpdate(
                                        appointment
                                      )
                                    }
                                  >
                                    Vitals
                                  </a>
                                  <a
                                    className={`link_vitals ${
                                      appointment?.report === true &&
                                      "link_vitals_success"
                                    }   ${
                                      appointment.appointment_status ===
                                        "Completed" && "disabled"
                                    } btn `}
                                    role="button"
                                    onClick={() =>
                                      selectAppointmentForReportUpload(
                                        appointment
                                      )
                                    }
                                  >
                                    Reports
                                  </a>
                                  {/* <div className="status_block status_action">
                                    <label>
                                      <input
  doctor/consultation                                     checked={
                                          appointment.attendee_status ===
                                          "Arrived"
                                        }
                                      />
                                      <span className="dot"></span>
                                    </label>
                                  </div> */}
                                </div>
                              </td>
                              <td className="text-center td_last_col">
                                <>
                                  <Link
                                    to={`/app-help?w=2&id=${
                                      appointment.id
                                    }&pid=${utils.getPatientId(
                                      appointment
                                    )}&reschedule=true`}
                                    className={`link_cancel me-2 btn ${
                                      appointment.attendee_status === "Arrived"
                                        ? "disabled"
                                        : ""
                                    }`}
                                  >
                                    Reschedule
                                  </Link>
                                  <a
                                    onClick={(
                                      e: React.MouseEvent<HTMLElement>
                                    ) => {
                                      openCancelDialog(e, {
                                        id: appointment.id,
                                        patientName:
                                          appointment.attendee_metadata.name,
                                      });
                                    }}
                                    className={`link_cancel me-2 btn ${
                                      appointment.attendee_status === "Arrived"
                                        ? "disabled"
                                        : ""
                                    }`}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Cancel
                                  </a>
                                  {/* <a
                                      onClick={(
                                        e: React.MouseEvent<HTMLElement>
                                      ) => {
                                        openCompleteDialog(e, {
                                          id: appointment.id,
                                          patientName:
                                            appointment.attendee_metadata.name,
                                        });
                                      }}
                                      className={`link_cancel btn ${
                                        appointment.attendee_status === "Arrive"
                                          ? "disabled"
                                          : ""
                                      }`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Complete
                                    </a> */}
                                </>
                              </td>
                              {/* <td className="text-center td_last_col">
                                {appointment.owner_metadata.code ===
                                "DR000001" && appointment.appointment_status !==
                                "Completed" ? (
                                  <button
                                    onClick={() =>
                                      selectPatientForTransfer(appointment)
                                    }>
                                    <TransferIcon />
                                  </button>
                                ) : (
                                  <TransferIconDisabled />
                                )}
                              </td> 
                              This disables transfer feaature for jr doctors
                              */}
                              {userProfile.healthCareProviderType ===
                                "Doctor" && (
                                <td className="text-center td_last_col">
                                  {appointment.appointment_status !==
                                  "Completed" ? (
                                    <button
                                      onClick={() =>
                                        selectPatientForTransfer(appointment)
                                      }
                                    >
                                      <TransferIcon />
                                    </button>
                                  ) : (
                                    <TransferIconDisabled />
                                  )}
                                </td>
                              )}
                              <td className="text-center td_last_col">
                                {appointment.payment ? (
                                  <a
                                    className={`link_cancel btn disabled`}
                                    style={{ cursor: "default" }}
                                  >
                                    Payment Done
                                  </a>
                                ) : (
                                  <>
                                    <a
                                      onClick={(
                                        e: React.MouseEvent<HTMLElement>
                                      ) => {
                                        openCompleteDialog(e, {
                                          id: appointment.id,
                                          patientName:
                                            appointment.attendee_metadata.name,
                                        });
                                      }}
                                      className={`link_cancel btn ${
                                        appointment.attendee_status === "Arrive"
                                          ? "disabled"
                                          : ""
                                      }`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Payment Done
                                    </a>
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <ConfirmPatientArriveDialog
                    appointmentInfo={selectedAppointment}
                    isOpen={isConfrmDialogVisible}
                    closeFn={closeConfirmDialog}
                  />
                  <CancelAppointmentDialog
                    appointmentInfo={selectedAppointment}
                    isOpen={isCancelDialogVisible}
                    closeFn={closeCancelDialog}
                  />
                  <CompleteAppointmentDialog
                    appointmentInfo={selectedAppointment}
                    isOpen={isCompleteDialogVisible}
                    closeFn={closeCompleteDialog}
                  />

                  {isPatientInformationDialogVisible && appointment && (
                    <PatientInformationDialog
                      appointment={appointment}
                      closeFn={() => {
                        setIsPatientInformationDialogVisible(false);
                      }}
                    />
                  )}
                  {isWaitingListVisible && (
                    <WaitingListDialog
                      closeFn={() => {
                        todayAppointments.refresh();
                        setIsWaitingListVisible(false);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isTransferPatientModalOpen && (
        <TransferPatientModal
          isTransferPatientModalOpen={isTransferPatientModalOpen}
          setIsTransferPatientModalOpen={setIsTransferPatientModalOpen}
          selectedAppointmentForTransfer={selectedAppointmentForTransfer}
          setSelectedAppointmentForTransfer={setSelectedAppointmentForTransfer}
        />
      )}
      {isAddVitalsModalOpen && (
        <AddVitalsModal
          isAddVitalsModalOpen={isAddVitalsModalOpen}
          setIsAddVitalsModalOpen={setIsAddVitalsModalOpen}
          selectedAppointment={selectedAppointmentForVitals}
          setSelectedAppointment={setSelectedAppointmentForVitals}
        />
      )}
      {isReportUploadDailogOpen && (
        <UploadReportDialog
          closeFn={closeReportsDialog}
          patientId={
            selectedAppointmentForReports?.attendee_metadata?.app_id as String
          }
          isFromAppointment={true}
          appointmentId={selectedAppointmentForReports?.id}
        />
      )}
    </>
  );
}
