import React from 'react'
import PageHeader from './PageHeader'

interface PageWrapperProps {
  children?: React.ReactNode;
}

export default function PageWrapper({ children }: PageWrapperProps) {
  return (
    <>
      <PageHeader />
      {children}
    </>
  )
}
