import AppointmentList from "../../models/domain/AppointmentList";
import CurrentSessionStats from "../../models/domain/CurrentSessionStats";

export default function appointmentListStats(appointmentList?: AppointmentList): CurrentSessionStats {
    if(appointmentList && appointmentList.appointments.length>0) {
        const existingPatients = appointmentList.appointments.filter(a => a.attendee_type == 'Existing').length;
        const totalPatients = appointmentList.appointments.length;
        return new CurrentSessionStats(totalPatients,
            (totalPatients - existingPatients),
            existingPatients,
            0, //TODO: To compute once online appointments are enabled
            totalPatients);
    } else {
        return new CurrentSessionStats(
            0,0, 0, 0, 0);
    }

}