import AppointmentDateSlot from './AppointmentDateSlot'

export default class AppointmentDate {
  constructor(
    public readonly date: string,
    public readonly available: boolean,
    public readonly buffer_available: boolean,
    public readonly slots: AppointmentDateSlot[]
  ) {
  }
}
