import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Paper,
  Grid,
  Icon,
  TextField,
  withStyles,
  Box,
} from "@material-ui/core";
import {
  VisibilityOutlined,
  VisibilityOffOutlined,
  AccountCircleOutlined,
} from "@material-ui/icons";
import { Link as NavigateLink } from "react-router-dom";
import { ReactComponent as Logo } from "../img/ic4-logo.svg";
import HttpClient, { HttpMutationOutput } from "../lib/HttpClient";
import { useSnackbarContext } from "../contexts/Snackbar";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 40,
    },
    paper: {
      backgroundColor: "#0E426A",
      alignItems: "center",
      justifyContent: "center",
      padding: 20,
      height: 420,
      width: 550,
    },
    title: {
      fontSize: 30,
      color: "#ffffff",
    },
    label: {
      fontSize: 12,
      color: "#ffffff",
      alignment: "right",
      padding: 10,
    },
    input: {
      width: 300,
      height: 30,
      margin: 0,
      borderColor: "rgba(52, 52, 52, 0.8)",
      // color,
    },
    linkSmall: {
      color: "#F78F2D",
      fontSize: 14,
    },
    linkLarge: {
      color: "#F78F2D",
      fontSize: 16,
    },
    button: {
      backgroundColor: "#F78F2D",
      color: "#ffffff",
      flex: 1,
      marginTop: "2em",
    },
    inputBox: {
      backgroundColor: "#FFFFFF",
      height: 45,
      width: 300,
      paddingTop: 5,
      borderRadius: 5,
    },
  })
);

interface State {
  username: string;
  password: string;
  showPassword: boolean;
}

const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "grey",
        // backgroundColor: "white",
      },
    },
  },
})(TextField);

export default function LoginPage() {
  const classes = useStyles();
  const [values, setValues] = React.useState<State>({
    username: "",
    password: "",
    showPassword: false,
  });
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { setSnackbar } = useSnackbarContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      // Save token to storage
      localStorage.setItem("token", token);
      // Fetch Profile
      getProfile(token);
    }
  }, [token]);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  function getProfile(token: String) {
    HttpClient.performGet<any>(`/api/v1/clinics/receptionist/profile`)
      .then(({ body }) => {
        if (!body?.status?.success) {
          setSnackbar({
            message: body?.status?.message,
            severity: "error",
          });
        } else {
          localStorage.setItem("profile", JSON.stringify(body?.profile));
          navigate("/today-appointment");
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setSnackbar({
          message: "Error occurred when fetching profile. " + e.message,
          severity: "error",
        });
        setIsLoading(false);
      });
  }

  function login() {
    if (values.password.length != 0 && values.username.length != 0) {
      setIsLoading(true);
      HttpClient.performPost(`/api/v1/clinics/receptionist/login`, {
        email: values.username,
        password: values.password,
      })
        .then((response: HttpMutationOutput<any>) => {
          if (!response?.body?.status?.success) {
            setSnackbar({
              message: response?.body?.status?.message,
              severity: "error",
            });
            setIsLoading(false);
          } else {
            console.log(response?.body);
            setToken(response?.body?.token);
            // TODO: redirect to home screen
          }
        })
        .catch((e) => {
          setSnackbar({
            message: "Error occurred during login. " + e.message,
            severity: "error",
          });
          setIsLoading(false);
        });
    } else {
      setSnackbar({
        message: "Please enter Username and Password",
        severity: "error",
      });
    }
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={5}
      className={classes.root}
    >
      <Grid item>
        <Logo />
      </Grid>
      <Grid item>
        <Paper className={classes.paper} elevation={0}>
          <Grid container direction="column" alignItems="center" spacing={3}>
            <Grid item xs={12}>
              <label className={classes.title}>Login</label>
            </Grid>

            <Grid item xs={7}>
              <label className={classes.label}>User name</label>
              <Box className={classes.inputBox}>
                <CustomTextField
                  className={classes.input}
                  id="outlined-adornment-username"
                  type="text"
                  value={values.username}
                  onChange={handleChange("username")}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <AccountCircleOutlined />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={7}>
              <label className={classes.label}>password</label>
              <Box className={classes.inputBox}>
                <CustomTextField
                  className={classes.input}
                  id="outlined-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon>
                          {values.showPassword ? (
                            <VisibilityOutlined
                              onClick={() =>
                                setValues({
                                  ...values,
                                  showPassword: false,
                                })
                              }
                            />
                          ) : (
                            <VisibilityOffOutlined
                              onClick={() =>
                                setValues({
                                  ...values,
                                  showPassword: true,
                                })
                              }
                            />
                          )}
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <NavigateLink to="/reset-password" className={classes.linkLarge}>
                Forgot password?
              </NavigateLink>
            </Grid>

            <Grid item xs={12}>
              <button
                className={
                  isLoading ? "primary_button_disabled" : "primary_button"
                }
                disabled={isLoading}
                onClick={login}
              >
                {isLoading ? "Logging in..." : "Login"}
              </button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
