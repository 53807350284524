import moment from "moment";
import React from "react";
import Profile from "../../models/domain/Profile";
import SpaceMetadata from "../../models/domain/SpaceMetadata";

export default function AppointmentSummary(props: {
  mainUserProfile: Profile;
  pid: string;
  appointmentDate: string | undefined;
  time: string | undefined;
  clinic: SpaceMetadata;
  doctorName: string;
  attendeeStatus: string | undefined;
  isOnline: boolean | undefined;
}) {
  //const userProfile = utils.retrieveUserProfile();
  return (
    <div className="left_patient_reg">
      <div className="box_patient_reg">
        <div className="row d-flex align-items-center">
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-5 fieldcol">
            <span className="txt_field">Patient ID :</span>
          </div>
          <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-7 fieldcol">
            <span className="txt_field fw-bolder">
              {props.mainUserProfile?.clinic_patient_id ?? props.pid ?? ""}
            </span>
          </div>
        </div>
      </div>
      <div className="box_patient_reg">
        <div className="row d-flex align-items-center">
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-5 fieldcol">
            <span className="txt_field field_title">Patient Name :</span>
          </div>
          <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-7 fieldcol">
            <span className="txt_field fw-bolder">
              {props.mainUserProfile?.name}
            </span>
          </div>
        </div>
      </div>
      <div className="box_patient_reg">
        <div className="row d-flex align-items-center">
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-5 fieldcol">
            <span className="txt_field">Doctor :</span>
          </div>
          <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-7 fieldcol">
            <span className="txt_field fw-bolder">{props.doctorName}</span>
          </div>
        </div>
      </div>
      <div className="box_patient_reg">
        <div className="row d-flex align-items-center">
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-5 fieldcol">
            <span className="txt_field">Clinic :</span>
          </div>
          <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-7 fieldcol">
            <span className="txt_field fw-bolder">
              {props.clinic ? props.clinic.name : ""}
              {props.isOnline ? " - Online" : ""}
            </span>
          </div>
        </div>
      </div>
      {props.appointmentDate !== undefined && (
        <div className="box_patient_reg">
          <div className="row d-flex align-items-center">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-5 fieldcol">
              <span className="txt_field">Date :</span>
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-7 fieldcol">
              <span className="txt_field fw-bolder">
                {moment(new Date(props.appointmentDate!)).format(
                  "Do MMM, dddd, YYYY"
                )}
              </span>
            </div>
          </div>
        </div>
      )}
      {props.time !== undefined && (
        <div className="box_patient_reg">
          <div className="row d-flex align-items-center">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-5 fieldcol">
              <span className="txt_field">Time :</span>
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-7 fieldcol">
              <span className="txt_field fw-bolder">
                {moment(props.time, "hh:mm A").format("hh:mm A")}
              </span>
            </div>
          </div>
        </div>
      )}
      {props.attendeeStatus === "Arrived" && (
        <div className="box_patient_reg">
          <div className="row d-flex align-items-center">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-5 fieldcol">
              <span className="txt_field">Attendee Status :</span>
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-7 fieldcol">
              <span className="txt_field fw-bolder">
                {props.attendeeStatus}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
