import Status from './Status'
import AppointmentDate from './AppointmentDate'

export default class AppointmentSlots {
  constructor(
    public readonly dates: AppointmentDate[],
    public readonly status: Status,
  ) {
  }
}
