import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import spps from "../../img/spps.png";
import anon from "../../img/icons/icon_user.svg";
import SearchPatientInformationDialog from "../appointments/SearchPatientInformationDialog";
import ReceptionistProfile from "../../models/domain/ReceptionistProfile";
import utils from "../../Utils";
import { Menu, MenuItem } from "@material-ui/core";

export default function PageHeader() {
  // Get profile from local storage during Init
  // First Receptionist Name: Shashikant V Tari
  const [userProfile, setUserProfile] = useState<ReceptionistProfile>(
    utils.emptyUserProfile
  );
  useEffect(() => {
    const _profile = utils.retrieveUserProfile();
    if (_profile) {
      setUserProfile(_profile);
    }
  }, []);

  const location = useLocation();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const logoutUser = () => {
    localStorage.clear();
    handleMenuClose();
    navigate("/");
  };

  return (
    <header>
      <div className="container-fluid">
        <div className="inner_header">
          <a href="#" className="logo">
            <img src="../img/ic4-logo.svg" alt="" className="img-fluid" />
          </a>
          <ul className="list-unstyled listnav">
            <li>
              <Link
                to="/today-appointment"
                className={
                  location.pathname === "/today-appointment" ? "active" : ""
                }
              >
                <span className="txt">Dashboard</span>
              </Link>
            </li>
            <li>
              <Link
                to="/app-help"
                className={location.pathname === "/app-help" ? "active" : ""}
              >
                <span className="txt">App Help</span>
              </Link>
            </li>
            <li>
              <Link
                to="/patient-info"
                className={
                  location.pathname === "/patient-info" ? "active" : ""
                }
              >
                <span className="txt">Patient Info</span>
              </Link>
            </li>
            <li>
              <a
                href="medicaltest.html"
                className="btn disabled"
                role="button"
                aria-disabled="true"
              >
                <span className="txt">Medical Test</span>
              </a>
            </li>
            <li>
              <a
                href="payment.html"
                className="btn disabled"
                role="button"
                aria-disabled="true"
              >
                <span className="txt">Payment</span>
              </a>
            </li>
            <li>
              <a
                href="doctor-schedule.html"
                className="btn disabled"
                role="button"
                aria-disabled="true"
              >
                <span className="txt">Dr. Schedule</span>
              </a>
            </li>
            <li>
              <a
                href="message.html"
                className="btn disabled"
                role="button"
                aria-disabled="true"
              >
                <span className="txt">Message</span>
              </a>
            </li>
          </ul>
          <div className="profile-block">
            <div className="dropdown">
              <button
                className="btn-profile dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
              >
                <span className="txt">{userProfile.name}</span>
                <div className="thumb">
                  <img
                    src={userProfile.name.match(/shashi/i) ? spps : anon}
                    onClick={handleMenu}
                    alt=""
                    className="img-fluid"
                  />
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => logoutUser()}>Logout</MenuItem>
                  </Menu>
                </div>
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <a className="dropdown-item" href="change-passsword.html">
                    <span className="icon">
                      <img
                        src="../img/icons/icon_account_circle_blue.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </span>
                    Change Password
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="login.html">
                    <span className="icon">
                      <img
                        src="../img/icons/icon_logout.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </span>
                    Sign Out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {dialogOpen && <SearchPatientInformationDialog closeFn={handleClose} />}
    </header>
  );
}
