import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/custom.scss";
import "./styles/index.css";
import { ProvideSnackbarContext } from "./contexts/Snackbar";
import { Provider, ErrorBoundary } from "@rollbar/react";
import { rollbarConfigV2 } from "./Utils";

const ErrorDisplay = (
  { error, resetError }: { error: Error | null; resetError: () => void } // <-- props passed to fallbackUI component
) => (
  <div>
    <h1>Error</h1>
    <div>{error?.toString()}</div>
    <br />
    <div>
      <a
        style={{ color: "blue" }}
        href="/receptionist"
        onClick={() => localStorage.clear()}>
        Go to home page
      </a>
    </div>
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <Provider config={rollbarConfigV2}>
      <ErrorBoundary fallbackUI={ErrorDisplay}>
        <ProvideSnackbarContext>
          <App />
        </ProvideSnackbarContext>
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
