import AttendeeMetadata from "./AttendeeMetadata";
import SpaceMetadata from "./SpaceMetadata";
import OwnerMetadata from "./OwnerMetadata";

export default class Appointment {
  constructor(
    public readonly id: string,
    public readonly date: string,
    public readonly startTime: string,
    public readonly attendee_type: string,
    public readonly attendee_metadata: AttendeeMetadata,
    public readonly owner_metadata: OwnerMetadata,
    public readonly space_metadata: SpaceMetadata,
    public readonly location: string,
    public readonly note: string
  ) {}
}
