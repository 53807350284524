export default class Profile {
  constructor (
    public readonly appId: string,
    public readonly clinic_patient_id: string,
    public readonly email: string,
    public readonly mobile: string,
    public readonly name: string,
    public readonly doctorId: string
  ) {
  }
}
