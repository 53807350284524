import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
} from '@material-ui/core';
import { GrClose } from 'react-icons/gr';
import { MdOutlineSearch } from 'react-icons/md';
import { useDebounce } from '../../controllers/useDebounce';
import HttpClient from '../../lib/HttpClient';
import { useSnackbarContext } from '../../contexts/Snackbar';

interface PatientInformationDialog {
  closeFn: () => void;
}

export default function SearchPatientInformationDialog ({
  closeFn,
}: PatientInformationDialog) {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const finalQuery = useDebounce<string>(searchQuery, 500);

  const [results, setResults] = useState<any[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  useEffect(
    () => {
      if (finalQuery && finalQuery?.trim().length > 4) {
        setIsSearching(true);
        HttpClient.performGet<any>(
          `/api/v1/users/search?query=${finalQuery?.trim()}`)
          .then(({ body }) => {
            if (body?.profiles && body.profiles.length > 0) {
              setResults(body?.profiles);
              setIsSearching(false);
            } else {
              setResults([]);
              setIsSearching(false);
            }
          }).catch(() => {
          setResults([]);
          setIsSearching(false);
        });
      } else {
        setResults([]);
      }
    },
    [finalQuery],
  );

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={true}
        onClose={closeFn}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          Patient Profile
          <IconButton
            aria-label="close"
            onClick={closeFn}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <GrClose/>
          </IconButton>
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <DialogContentText>
            <div>
              <FormControl fullWidth>
                <Input
                  placeholder={"Search Patient by Name/ID/Mobile number"}
                  autoComplete={"off"}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  startAdornment={<InputAdornment
                    position="start"><MdOutlineSearch/></InputAdornment>}
                />
              </FormControl>
            </div>
          </DialogContentText>
          <DialogContentText>
            <div className={'mt-2'}>
              <div className="main_content mt-0">
                <div className="inner_content p-0">
                  <div className="appointment_block p-0">
                    <div className="appointment_body">
                      <div className="table-responsive">
                        <table className="custom_table table">
                          <thead>
                          <tr>
                            <th>
                              <span className="txt fw-bold">Patient Name</span>
                            </th>
                            <th>
                              <span className="txt fw-bold">Patient ID</span>
                            </th>
                            <th>
                              <span className="txt fw-bold">Mobile Number</span>
                            </th>
                            <th>
                              <span className="txt fw-bold">Last visited</span>
                            </th>
                            <th className="text-center th_last_col">
                              <span className="txt fw-bold"></span>
                            </th>
                            <th className="text-center th_last_col">
                              <span className="txt fw-bold"></span>
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          {(results && results.length > 0) ? results.map(
                            (profile, index) => {
                              return <SearchPatientTableRow key={profile.id}
                                                            profile={profile}/>;
                            }) : (
                            <tr>
                              <td colSpan={6} className={'text-center'}>No
                                results found
                              </td>
                            </tr>
                          )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

function SearchPatientTableRow ({ profile }: {
  profile: any,
}) {
  const { setSnackbar } = useSnackbarContext();
  const [editing, setEditing] = useState(false);
  const [mobile, setMobile] = useState<string>(profile.mobile);

  return (
    <tr>
      <td>
        <span className="txt">{profile.name}</span>
      </td>
      <td>
        <span className="txt">{profile.appId ??
          profile.clinicPatientId}</span>
      </td>
      <td>
        {editing ? (
          <input type="text"
                 className="field_input"
                 name="mobile"
                 onChange={(e) => {setMobile(e.target.value);}}
                 value={mobile}
          />
        ) : <span className="txt">{profile.mobile}</span>}
      </td>
      <td>
        <span
          className="txt">{profile.visitDate}</span>
      </td>
      <td className="text-center td_last_col">
        {
          editing ? (
            <>
              <a
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  HttpClient.performPatch<any>(`/api/v1/users/${profile.id}`,
                    {
                      'mobile': mobile,
                    }).then((response) => {
                    if (!response?.body?.status?.success) {
                      setSnackbar({
                        message: response?.body?.status?.message,
                        severity: 'error',
                      });
                    } else {
                      setSnackbar({
                        message: 'Mobile number updated successfully!',
                        severity: 'success',
                      });
                      profile.mobile = mobile;
                      setEditing(false);
                    }
                  }).catch((e) => {
                    console.log(e);
                  }).finally(() => {
                    console.log('finally!');
                  });
                }}
                className={`link_cancel me-2 btn`}
                style={{ cursor: 'pointer' }}
              >
                Save
              </a>
              <a
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  setEditing(false);
                  setMobile(profile.mobile);
                }}
                className={`link_cancel me-2 btn`}
                style={{ cursor: 'pointer' }}
              >
                Cancel
              </a>
            </>
          ) : (
            <a
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                setEditing(true);
              }}
              className={`link_cancel me-2 btn`}
              style={{ cursor: 'pointer' }}
            >
              Edit Profile
            </a>
          )
        }
      </td>
      <td>
        <span className="txt"></span>
      </td>
    </tr>
  );

}