import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "../../pages/LoginPage";
import ResetPasswordPage from "../../pages/ResetPasswordPage";
import AppHelpPage from "../../pages/AppHelpPage";
import TodayAppointmentPage from "../../pages/TodayAppointmentPage";
import UpcomingAppointmentPage from "../../pages/UpcomingAppointmentPage";
import HistoryAppointmentPage from "../../pages/HistoryAppointmentPage";
import PatientInfo from "../../pages/PatientInfo";
import DoctorSessionsPage from "../../pages/DoctorSessionsPage";

const appRoutes = [
  {
    path: "/dashboard",
    component: DoctorSessionsPage,
  },
  {
    path: "/",
    component: LoginPage,
  },
  {
    path: "/reset-password",
    component: ResetPasswordPage,
  },
  {
    path: "/app-help",
    component: AppHelpPage,
  },
  {
    path: "/today-appointment",
    component: TodayAppointmentPage,
  },
  {
    path: "/upcoming-appointment",
    component: UpcomingAppointmentPage,
  },
  {
    path: "/history-appointment",
    component: HistoryAppointmentPage,
  },
  {
    path: "/patient-info",
    component: PatientInfo,
  },
];

export default function AppRoutes() {
  return (
    <Router>
      <Routes>
        {appRoutes.map((route, i) => (
          <Route key={i} path={route.path} element={<route.component />} />
        ))}
      </Routes>
    </Router>
  );
}
