import { format } from "date-fns";
import { useEffect, useState } from "react";
import utils from "../../Utils";
import ReceptionistProfile from "../../models/domain/ReceptionistProfile";
import DoctorConsultationSessionSummary from "../../models/domain/DoctorConsultationSessionSummary";
import HttpClient from "../../lib/HttpClient";
import { useSnackbarContext } from "../../contexts/Snackbar";
import AppointmentListMapper from "../../mappers/AppointmentListMapper";
import AppointmentList from "../../models/domain/AppointmentList";
import appointmentListStats from "../../controllers/appointments/appointmentListStats";
import { useNavigate } from "react-router-dom";

interface AppointmentListEnriched {
  sessionDetails: AppointmentList;
  hcProviderId: String;
}

export default function DoctorSessionsGrid() {
  const today = new Date().toISOString().split("T")[0];
  const [userProfile, setUserProfile] = useState<ReceptionistProfile>(
    utils.emptyUserProfile
  );
  const [isLoadingSessions, setIsLoadingSessions] = useState<Boolean>(true);
  const [sessions, setSessions] = useState<DoctorConsultationSessionSummary[]>([
    utils.mockDoctorConsultationSession,
    utils.mockDoctorConsultationSession,
  ]);
  const { setSnackbar } = useSnackbarContext();
  const navigate = useNavigate();

  const getAppointments = (doctorId: String) => {
    return HttpClient.performGet<any>(
      `/api/v1/appointments/${userProfile.clinicId}?date=${today}&ownerId=${doctorId}`
    ).then(({ body }) => {
      let _response = undefined;
      if (body?.status?.success) {
        _response = AppointmentListMapper.mapResponseToModel(body);
      }
      return _response;
    });
  };

  useEffect(() => {
    const _profile = utils.retrieveUserProfile();
    if (_profile) {
      setUserProfile(_profile);
    }
  }, []);

  useEffect(() => {
    if (userProfile.doctorId !== "") {
      let fetchAppointmentRequests = userProfile.associatedHealthCareProviders
        .filter((hcProfile) => hcProfile.providerType === "Doctor")
        .map((hcProfile) => {
          return getAppointments(hcProfile.id).then((response) => {
            return {
              sessionDetails: response,
              hcProviderId: hcProfile.id,
            };
          });
        });
      Promise.all(fetchAppointmentRequests)
        .then((responses) => {
          let _successfulResponses = responses.filter(
            (response) => response?.sessionDetails?.status.success === true
          );
          // If we failed to fetch appointment list for even one of the associatedHCProviders then throw an error
          if (
            _successfulResponses.length !==
            userProfile.associatedHealthCareProviders.length
          ) {
            setSnackbar({
              message: "Failed to retrieve some of the sessions",
              severity: "error",
            });
          } else {
            let _sessionDetails = _successfulResponses.map((response) => {
              let _appointmentList = response as AppointmentListEnriched;
              let _appointmentStats = appointmentListStats(
                _appointmentList.sessionDetails
              );
              let _hcProviderProfile =
                userProfile.associatedHealthCareProviders.find(
                  (profile) => profile.id === _appointmentList.hcProviderId
                )!!;
              return new DoctorConsultationSessionSummary(
                _hcProviderProfile,
                _appointmentList.sessionDetails.appointments.length,
                _appointmentStats.new_patient_count,
                _hcProviderProfile.sessionDetails
              );
            });
            setSessions(_sessionDetails);
          }
        })
        .catch((e) => {
          setSnackbar({
            message: "Error occurred when fetching sessions. " + e.message,
            severity: "error",
          });
        })
        .finally(() => setIsLoadingSessions(false));
    }
  }, [userProfile]);

  const DoctorSession: React.FC<{
    session: DoctorConsultationSessionSummary;
  }> = (props) => {
    return (
      <div
        className={
          "col-xxl-4 col-xl-4 col-lg-4 col-md-4 fieldcol mt-2 " +
          (isLoadingSessions ? " component-loading" : "")
        }
      >
        <div className="box_schedule">
          <h3 className="subtitle text-center">
            {props.session.healthCareProvider.name}
          </h3>
          <div className="row justify-content-md-center">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 mt-4">
              <div className="txt_num_div">
                <a href="#" className="txt_num_div_bkg">
                  <span className="txt_num">
                    {props.session.appointmentCount}
                  </span>
                </a>
              </div>
              <div className="txt_div">Appointments</div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 mt-4">
              <div className="txt_num_div">
                <a href="#" className="txt_num_div_bkg">
                  <span className="txt_num">
                    {props.session.newPatientCount}
                  </span>
                </a>
              </div>
              <div className="txt_div">New Patients</div>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-2">
              <span className="txt_time mt-4">
                <span className="icon">
                  <i className="fa fa-clock-o"></i>
                </span>
                {
                  //"8:00 AM To 12:30 PM"
                  props.session.sessionTimingFormatted
                }
              </span>
            </div>
          </div>
          <div className="row justify-content-center mt-auto">
            <button
              className="col-4 btn_primary"
          
              onClick={(event) => {
                localStorage.setItem(
                  "selectedDoctor",
                  JSON.stringify(props.session.healthCareProvider)
                );
                localStorage.removeItem("preferredDoctorForAppointmentBooking");
                navigate("/today-appointment");
              }}
            >
              Start
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderDoctorSessions = () => {
    return sessions.map((session, index) => {
      return <DoctorSession session={session} key={index} />;
    });
  };

  return (
    <>
      <div className="main_content">
        <div className="container-fluid">
          <div className="inner_content">
            <div className="date_block">
              <div className="row">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 gridcol">
                  <div className="title_block text-center">
                    <h2 className="headtitle">
                      {isLoadingSessions
                        ? "Loading today's sessions..."
                        : "Today's Sessions"}
                    </h2>
                    <span className="txt_date">
                      {format(new Date(), "dd-MM-yyyy")}
                    </span>
                  </div>
                  <div className="row justify-content-md-center">
                    {renderDoctorSessions()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
