import React from 'react'
import CurrentSessionStats from "../../models/domain/CurrentSessionStats";

export default function CurrentSessionStatsWidget(props: {stats: CurrentSessionStats}) {
    return (
        <div className="appointment_head_left">
            <ul className="list-unstyled list_app_status">
                <li>
                    <a href="#">
                        <span className="txt_num">{props.stats.patient_count}</span>
                        <span className="txt_word">Total no. of patient</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <span className="txt_num">{props.stats.new_patient_count}</span>
                        <span className="txt_word">New Patient</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <span className="txt_num">{props.stats.existing_patient_count}</span>
                        <span className="txt_word">Existing Patient</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <span className="txt_num">{props.stats.online_consultation_count}</span>
                        <span className="txt_word">Online Consultation</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <span className="txt_num">{props.stats.inperson_consultation_count}</span>
                        <span className="txt_word">Inperson</span>
                    </a>
                </li>
            </ul>
        </div>
    )
}