import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import PageWrapper from "../components/layout/PageWrapper";
import { useWizard, Wizard } from "react-use-wizard";
import useExistingUser from "../controllers/appointments/useExistingUser";
import Profile from "../models/domain/Profile";
import useAppointmentSlots from "../controllers/appointments/useAppointmentSlots";
import { Alert } from "@material-ui/lab";
import AppointmentDateSlot from "../models/domain/AppointmentDateSlot";
import PatientSearchQuery from "../models/domain/PatientSearchQuery";
import { useSnackbarContext } from "../contexts/Snackbar";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import HttpClient from "../lib/HttpClient";
import AppointmentDate from "../models/domain/AppointmentDate";
import AppointmentSummary from "../components/appointments/AppointmentSummary";
import utils from "../Utils";
import AppointmentInfo from "../models/domain/AppointmentInfo";
import CancelAppointmentDialog from "../components/appointments/CancelAppointmentDialog";
import {
  Container,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ProfileMapper from "../mappers/ProfileMapper";
import moment from "moment";
import { MdOutlineSearch } from "react-icons/md";
import UploadReportDialog from "../components/reports/UploadReportDialog";
import Reports from "../components/reports/Reports";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import SpaceMetadata from "../models/domain/SpaceMetadata";
import PreferredDoctors from "../components/appointments/PreferredDoctors";
import UpcomingUserAppointment from "../models/domain/UpcomingUserAppointment";
import useUpcomingUserVisit from "../controllers/appointments/useUpcomingUserVisit";
import UpcomingUserWaitlist from "../models/domain/UpcomingUserWaitlist";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  //https://github.com/mui/material-ui/issues/21015#issuecomment-663208786
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function AppHelpPage() {
  const receptionistProfile = utils.retrieveUserProfile();
  let [searchParams, setSearchParams] = useSearchParams();
  let [mainUserProfile, setMainUserProfile] = useState<Profile>();
  let [multipleProfiles, setMultipleProfiles] = useState<Profile[]>([]);
  let [selectedDate, setSelectedDate] = useState<string>("");
  let [oldAppointmentId, setOldAppointmentId] = useState<string>("");
  let [selectedTimeSlot, setSelectedTimeSlot] = useState<AppointmentDateSlot>();
  let [userType, setUserType] = useState<string>("existing");

  let [isAppointmentTab, setIsAppointmentTab] = useState(true);
  let [showUploadDialog, setShowUploadDialog] = useState(false);

  const [patientSearchParameter, setPatientSearchParameter] =
    useState<PatientSearchQuery>();

  const classes = useStyles();
  const [value, setValue] = React.useState(2);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const userProfile = utils.retrieveUserProfile();

  const isScheduledBySelf = (appointmentAtClinic: string) => {
    return userProfile.clinicName === appointmentAtClinic;
  };

  useEffect(() => {
    setMainUserProfile(undefined);
  }, [userType]);

  const Step1 = () => {
    const { nextStep, goToStep } = useWizard();
    const { setSnackbar } = useSnackbarContext();

    const [receptionistProfile, setReceptionistProfile] = useState(
      utils.retrieveUserProfile()
    );

    //set prefered doctor default to first doctor from receptionist profile associated health providers array
    const [preferedDoctor, setPreferedDoctor] = useState(
      receptionistProfile.associatedHealthCareProviders.find(
        (healthcareProvider) =>
          healthcareProvider.providerType === "Doctor" ||
          healthcareProvider.providerType === "Dietitian"
      ) || utils.defaultDoctor
    );

    if (searchParams.get("w")) {
      goToStep(parseInt(searchParams?.get("w") || "0"));
    }

    // @ts-ignore
    return (
      <div className="first_step">
        <Formik
          initialValues={{
            newEmail: "",
            newMobile: "",
            firstName: "",
            middleName: "",
            lastName: "",
            mobile: "",
            practoId: "",
            searchName: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (userType === "new") {
              HttpClient.performPost<any>(`/api/v1/users`, {
                firstName: values.firstName,
                middleName: values.middleName,
                lastName: values.lastName,
                mobile: String(values.newMobile),
                email: values.newEmail,
                healthProviderId: preferedDoctor.id,
                clinicId: receptionistProfile.clinicId,
              })
                .then((response) => {
                  if (!response?.body?.status?.success) {
                    setSnackbar({
                      message: response?.body?.status?.message,
                      severity: "error",
                    });
                  } else {
                    setSnackbar({
                      message: "New User successfully!",
                      severity: "success",
                    });

                    setMainUserProfile({
                      name: `${values.firstName} ${values.middleName} ${values.lastName}`,
                      clinic_patient_id:
                        response?.body?.appId ?? response?.body?.patientId,
                      mobile: values.newMobile,
                      email: values.newEmail,
                      appId: "",
                      doctorId: preferedDoctor.id,
                    });
                    nextStep();
                  }
                })
                .catch((e) => {
                  console.log(e);
                })
                .finally(() => {
                  console.log("finally!");
                  setSubmitting(false);
                });
            } else {
              let getExistingUserUrl = ``;
              if (!values.mobile && !values.practoId && !values.searchName) {
                setSnackbar({
                  message:
                    multipleProfiles?.length > 1
                      ? "Please select one of the patients from the list"
                      : "Please enter mobile number, name or patient id",
                  severity: "error",
                });
                return;
              }
              if (values.practoId) {
                getExistingUserUrl = `/api/v1/users/search?patientId=${values.practoId}`;
                setPatientSearchParameter({
                  type: "appId",
                  value: values.practoId,
                });
              } else if (values.mobile) {
                getExistingUserUrl = `/api/v1/users/search?mobile=${values.mobile}`;
                setPatientSearchParameter({
                  type: "mobile",
                  value: values.mobile,
                });
              } else {
                getExistingUserUrl = `/api/v1/users/search?name=${values.searchName}`;
                setPatientSearchParameter({
                  type: "name",
                  value: values.searchName,
                });
              }
              HttpClient.performGet<any>(getExistingUserUrl)
                .then(({ body }) => {
                  if (
                    body?.profiles &&
                    body?.profiles.length === 1 &&
                    body?.profiles[0]
                  ) {
                    setMultipleProfiles([]);
                    if (isAppointmentTab) {
                      //validation to disallow booking from practo id.
                      if (!body?.profiles[0].appId) {
                        setSnackbar({
                          message:
                            "This patient is not registered on the IC4 app. Please register the patient on the IC4 app. IC4 registration number required for booking the appointment.",
                          severity: "error",
                        });
                        return;
                      }

                      setMainUserProfile({
                        name: body?.profiles[0].name,
                        clinic_patient_id:
                          body?.profiles[0].appId ??
                          body?.profiles[0].clinicPatientId,
                        mobile: body?.profiles[0].mobile,
                        email: body?.profiles[0].email,
                        appId: body?.profiles[0].appId,
                        doctorId: body?.profiles[0].doctorId,
                      });

                      nextStep();
                    } else {
                      if (body?.profiles[0].appId) {
                        setMainUserProfile({
                          name: body?.profiles[0].name,
                          clinic_patient_id:
                            body?.profiles[0].appId ??
                            body?.profiles[0].clinicPatientId,
                          mobile: body?.profiles[0].mobile,
                          email: body?.profiles[0].email,
                          appId: body?.profiles[0].appId,
                          doctorId: body?.profiles[0].doctorId,
                        });
                        nextStep();
                      } else {
                        setSnackbar({
                          message: "Error fetching patient",
                          severity: "error",
                        });
                      }
                    }
                  } else if (body?.profiles && body?.profiles.length > 1) {
                    setMultipleProfiles(
                      body?.profiles.map(ProfileMapper.mapResponseToModel)
                    );
                  } else {
                    setMultipleProfiles([]);
                    setSnackbar({
                      message:
                        body?.status?.message ?? "Patient does not exist",
                      severity: "error",
                    });
                  }
                })
                .catch((e) => {
                  setMultipleProfiles([]);
                  setSnackbar({
                    message: e.body?.status?.message,
                    severity: "error",
                  });
                  console.log("finally!");
                  setSubmitting(false);
                });
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            submitForm,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="content clearfix">
                <section>
                  <div className="patient_reg_block">
                    <div className="row">
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 gridcol">
                        <div className="left_patient_reg v_right_divider">
                          <div className="box_patient_reg">
                            <div className="row d-flex align-items-center">
                              <div className="col-xxl-3 col-xl-3 col-lg-3 fieldcol">
                                <div className="field_group">
                                  <label className="field_lbl">
                                    Patient Type
                                  </label>
                                </div>
                              </div>
                              <div className="col-xxl-9 col-xl-9 col-lg-9 fieldcol">
                                <div className="field_group">
                                  <ul className="list-unstyled list_gender_radio">
                                    <li>
                                      <label className="field_lbl">
                                        <input
                                          type="radio"
                                          name="patientype"
                                          value={"existing"}
                                          onChange={(e) =>
                                            setUserType("existing")
                                          }
                                          checked={userType === "existing"}
                                        />
                                        <span className="box_dot"></span>
                                        <span className="txt">Existing</span>
                                      </label>
                                    </li>
                                    {isAppointmentTab ? (
                                      <li>
                                        <label className="field_lbl">
                                          <input
                                            type="radio"
                                            name="patientype"
                                            value={"new"}
                                            checked={userType === "new"}
                                            onChange={(e) => setUserType("new")}
                                          />
                                          <span className="box_dot"></span>
                                          <span className="txt">New</span>
                                        </label>
                                      </li>
                                    ) : (
                                      <></>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          {userType === "new" ? (
                            <>
                              <div className="box_patient_reg">
                                <div className="row d-flex align-items-center">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 fieldcol">
                                    <div className="field_group">
                                      <label className="field_lbl">
                                        First name
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-xxl-9 col-xl-9 col-lg-9 fieldcol">
                                    <div className="field_group">
                                      <input
                                        type="text"
                                        className="field_input"
                                        name="firstName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.firstName}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="box_patient_reg">
                                <div className="row d-flex align-items-center">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 fieldcol">
                                    <div className="field_group">
                                      <label className="field_lbl">
                                        Middle name
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-xxl-9 col-xl-9 col-lg-9 fieldcol">
                                    <div className="field_group">
                                      <input
                                        type="text"
                                        className="field_input"
                                        name="middleName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.middleName}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="box_patient_reg">
                                <div className="row d-flex align-items-center">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 fieldcol">
                                    <div className="field_group">
                                      <label className="field_lbl">
                                        Last name
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-xxl-9 col-xl-9 col-lg-9 fieldcol">
                                    <div className="field_group">
                                      <input
                                        type="text"
                                        className="field_input"
                                        name="lastName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.lastName}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="box_patient_reg">
                                <div className="row d-flex align-items-center">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 fieldcol">
                                    <div className="field_group">
                                      <label className="field_lbl">Email</label>
                                    </div>
                                  </div>
                                  <div className="col-xxl-9 col-xl-9 col-lg-9 fieldcol">
                                    <div className="field_group">
                                      <input
                                        type="email"
                                        className="field_input"
                                        name="newEmail"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.newEmail}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="box_patient_reg">
                                <div className="row d-flex align-items-center">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 fieldcol">
                                    <div className="field_group">
                                      <label className="field_lbl">
                                        Mobile No
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-xxl-9 col-xl-9 col-lg-9 fieldcol">
                                    <div className="field_group">
                                      <input
                                        type="number"
                                        className="field_input"
                                        name="newMobile"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.newMobile}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="box_patient_reg">
                                <div className="row d-flex align-items-center">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 fieldcol">
                                    <div className="field_group">
                                      <label className="field_lbl">
                                        Patient ID
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-xxl-9 col-xl-9 col-lg-9 fieldcol">
                                    <div className="field_group">
                                      <input
                                        type="text"
                                        className="field_input"
                                        name="practoId"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.practoId}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="box_patient_reg">
                                <div className="row d-flex align-items-center">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 fieldcol">
                                    <div className="field_group">
                                      <label className="field_lbl">
                                        Mobile No
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-xxl-9 col-xl-9 col-lg-9 fieldcol">
                                    <div className="field_group">
                                      <input
                                        type="text"
                                        className="field_input"
                                        name="mobile"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.mobile}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="box_patient_reg">
                                <div className="row d-flex align-items-center">
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 fieldcol">
                                    <div className="field_group">
                                      <label className="field_lbl">Name</label>
                                    </div>
                                  </div>
                                  <div className="col-xxl-9 col-xl-9 col-lg-9 fieldcol">
                                    <div className="field_group">
                                      <input
                                        type="text"
                                        className="field_input"
                                        name="searchName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.searchName}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {userType === "new" ? (
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 gridcol">
                          <div className="right_patient_reg">
                            <div className="box_patient_reg">
                              <h4 className="maintitle_block mb-0">
                                Your preferred doctor
                              </h4>
                              <div className="mb-2">
                                <PreferredDoctors
                                  enabledDoctors={
                                    mainUserProfile?.doctorId
                                      ? [mainUserProfile!!.doctorId]
                                      : [userProfile.doctorId]
                                  }
                                  isCreateNewPatient={true}
                                  preferedDoctor={preferedDoctor}
                                  setPreferedDoctor={setPreferedDoctor}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 gridcol">
                          <div className="right_patient_reg">
                            <div className="box_patient_reg">
                              {multipleProfiles.length > 1 && (
                                <>
                                  <div className="primary-text">
                                    {patientSearchParameter?.type == "mobile"
                                      ? `
                                    Multiple Patient Registered with 
                                    ${multipleProfiles[0].mobile}`
                                      : patientSearchParameter?.type == "name"
                                      ? `
                                    Multiple Patient Registered with name
                                    ${patientSearchParameter?.value}`
                                      : ``}
                                  </div>
                                  <TableContainer component={"div"}>
                                    <Table aria-label="simple table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Patient Name</TableCell>
                                          <TableCell>Patient ID</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {multipleProfiles.map((profile) => (
                                          <TableRow
                                            onClick={() => {
                                              //validation to disallow booking from practo id.
                                              if (!profile.appId) {
                                                setSnackbar({
                                                  message:
                                                    "This patient is not registered on the IC4 app. Please register the patient on the IC4 app. IC4 registration number required for booking the appointment.",
                                                  severity: "error",
                                                });
                                                return;
                                              }
                                              setMainUserProfile({
                                                ...profile,
                                                clinic_patient_id:
                                                  profile.appId ??
                                                  profile.clinic_patient_id,
                                              });
                                              nextStep();
                                            }}
                                            style={{ cursor: "pointer" }}
                                            hover
                                            key={profile.name}
                                          >
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {profile.name}
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {profile.appId ??
                                                profile.clinic_patient_id}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
              {isAppointmentTab ? (
                <div className="actions clearfix">
                  <ul role="menu" aria-label="Pagination">
                    <li style={{ visibility: "hidden" }}>
                      <span />
                    </li>
                    <li aria-hidden="false" aria-disabled="false">
                      <button type={"submit"}>Next</button>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="actions clearfix">
                  <ul role="menu" aria-label="Pagination">
                    <li style={{ visibility: "hidden" }}>
                      <span />
                    </li>
                    <li aria-hidden="false" aria-disabled="false">
                      <button>Upload Reports</button>
                    </li>
                  </ul>
                </div>
              )}
            </form>
          )}
        </Formik>
      </div>
    );
  };

  const Step2 = () => {
    const upcomingUserVisit = useUpcomingUserVisit(
      mainUserProfile!.clinic_patient_id,
      userProfile
    );

    const [isCancelDialogVisible, setCancelDialogVisibility] =
      React.useState(false);
    const [selectedAppointment, setSelectedAppointment] =
      React.useState<AppointmentInfo>({
        id: "",
        patientName: "",
      });
    const { nextStep, previousStep } = useWizard();
    const [isNextDisabled, disableNext] = React.useState(false);

    const isPreferredDoctorInCurrentClinic = (preferredDoctor: string) => {
      console.log(preferredDoctor);
      return (
        userProfile.associatedHealthCareProviders.filter(
          (profile) => profile.id === preferredDoctor
        ).length > 0
      );
    };

    const isDietitinModule = () =>
      userProfile.healthCareProviderType === "Dietitian";

    useEffect(
      () => {
        // Has an appointment OR
        // Has a waitlist OR (This is omitted for now on purpose)
        // Has an error OR
        // Cannot find preferred doctor
        // Then disabled Next button
        console.log("upcomming user visit", upcomingUserVisit);
        if (
          (hasUpcomingAppointment() && !upcomingVisitHasError()) ||
          (!isPreferredDoctorInCurrentClinic(
            mainUserProfile?.doctorId
              ? mainUserProfile?.doctorId
              : (utils?.defaultDoctor.id as string)
          ) &&
            !isDietitinModule)
        ) {
          disableNext(true);
        } else {
          disableNext(false);
        }
      },
      // This is important
      [upcomingUserVisit, mainUserProfile]
    );

    const openCancelDialog = (
      event: React.MouseEvent<HTMLElement>,
      appointment: AppointmentInfo
    ) => {
      event.preventDefault();
      setSelectedAppointment(appointment);
      setCancelDialogVisibility(true);
    };

    const closeCancelDialog = (toRefresh: boolean = false) => {
      if (toRefresh) {
        upcomingUserVisit.upcomingAppointment.refresh();
        upcomingUserVisit.upcomingWaitlist.refresh();
      }
      setCancelDialogVisibility(false);
    };

    const isUpcomingVisitLoading = () =>
      upcomingUserVisit.upcomingAppointment.isLoading ||
      upcomingUserVisit.upcomingWaitlist.isLoading;
    const upcomingVisitHasError = () =>
      upcomingUserVisit.upcomingAppointment.error !== undefined &&
      upcomingUserVisit.upcomingWaitlist.error !== undefined;
    const upcomingVisitError = () => {
      if (upcomingUserVisit.upcomingAppointment.error)
        return upcomingUserVisit.upcomingAppointment.error;
      else if (upcomingUserVisit.upcomingWaitlist.error)
        return upcomingUserVisit.upcomingWaitlist.error;
      else return "";
    };
    const hasUpcomingAppointment = () =>
      upcomingUserVisit.upcomingAppointment.resource?.status.success &&
      upcomingUserVisit.upcomingAppointment.resource.appointment;
    const hasUpcomingWaitlist = () =>
      upcomingUserVisit.upcomingWaitlist.resource?.status.success &&
      upcomingUserVisit.upcomingWaitlist.resource.waitlist;

    const UpcomingAppointmentSummary = (props: {
      upcomingAppointment: UpcomingUserAppointment;
    }) => (
      <>
        <div className="primary-text">
          The appointment is already scheduled.
        </div>
        <AppointmentSummary
          mainUserProfile={mainUserProfile!}
          pid={searchParams.get("pid") ?? ""}
          doctorName={
            props.upcomingAppointment.appointment!.owner_metadata.name
          }
          appointmentDate={props.upcomingAppointment.appointment!.date}
          time={props.upcomingAppointment.appointment!.start_time}
          clinic={props.upcomingAppointment.appointment!.space_metadata}
          attendeeStatus={
            props.upcomingAppointment.appointment?.attendee_status
          }
          isOnline={props.upcomingAppointment.appointment?.isOnline}
        />
        {props.upcomingAppointment.appointment?.attendee_status !==
          "Arrived" && (
          <div className="actions-clone clearfix">
            <ul role="menu" aria-label="Pagination">
              <li aria-hidden="false" aria-disabled="false">
                <a
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    openCancelDialog(e, {
                      id: props.upcomingAppointment.appointment!.id,
                      patientName:
                        props.upcomingAppointment.appointment!.attendee_metadata
                          .name,
                    });
                  }}
                  className="link_cancel"
                  style={{ cursor: "pointer" }}
                >
                  Cancel
                </a>
              </li>
              <li aria-hidden="false" aria-disabled="false">
                {isScheduledBySelf(
                  props.upcomingAppointment.appointment!.space_metadata.name
                ) && (
                  <Link
                    reloadDocument={true}
                    to={`/app-help?w=2&id=${
                      props.upcomingAppointment.appointment!.id
                    }&pid=${utils.getPatientId(
                      props.upcomingAppointment.appointment!
                    )}&reschedule=true`}
                    className="link_cancel me-3"
                  >
                    Reschedule Appointment
                  </Link>
                )}
              </li>
            </ul>
          </div>
        )}
        <CancelAppointmentDialog
          appointmentInfo={selectedAppointment}
          isOpen={isCancelDialogVisible}
          closeFn={() => closeCancelDialog(true)}
        />
      </>
    );

    const UpcomingWaitlistSummary = (props: {
      upcomingWaitlist: UpcomingUserWaitlist;
    }) => (
      <>
        <div className="primary-text">The waitlist is already scheduled.</div>
        <AppointmentSummary
          mainUserProfile={mainUserProfile!}
          pid={searchParams.get("pid") ?? ""}
          doctorName={props.upcomingWaitlist.waitlist!.owner_metadata.name}
          appointmentDate={props.upcomingWaitlist.waitlist!.date}
          time={props.upcomingWaitlist.waitlist!.startTime}
          clinic={props.upcomingWaitlist.waitlist?.space_metadata!}
          attendeeStatus={undefined}
          isOnline={false}
        />
      </>
    );

    const UpcomingVisitSummary = () => (
      <>
        <br />
        <h4 className="maintitle_block">
          {hasUpcomingWaitlist() ? (
            <>Waitlist Booking Details</>
          ) : (
            <>Appointment Booking Details</>
          )}
        </h4>
        {isUpcomingVisitLoading() ? (
          <>checking for upcoming appointments and waitlist...</>
        ) : upcomingVisitHasError() ? (
          <>{utils.getErrorMessage(JSON.stringify(upcomingVisitError()))}</>
        ) : hasUpcomingAppointment() ? (
          <UpcomingAppointmentSummary
            upcomingAppointment={
              upcomingUserVisit.upcomingAppointment!.resource!
            }
          />
        ) : hasUpcomingWaitlist() ? (
          <UpcomingWaitlistSummary
            upcomingWaitlist={upcomingUserVisit.upcomingWaitlist!.resource!}
          />
        ) : (
          <>{/*upcomingUserAppointment.resource?.status.message*/}</>
        )}
      </>
    );

    return (
      <div className="second_step">
        <div className="content clearfix">
          <section>
            <div className="patient_reg_block">
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 gridcol">
                  <div className="left_patient_reg v_right_divider">
                    <div className="box_patient_reg">
                      <div className="row d-flex align-items-center">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 fieldcol">
                          <div className="field_group">
                            <label className="field_lbl">Patient Type</label>
                          </div>
                        </div>
                        <div className="col-xxl-9 col-xl-9 col-lg-9 fieldcol">
                          <div className="field_group">
                            <ul className="list-unstyled list_gender_radio">
                              <li>
                                <label className="field_lbl">
                                  <input
                                    type="radio"
                                    name="patientype"
                                    checked={userType === "existing"}
                                    onChange={(e) => null}
                                  />
                                  <span className="box_dot"></span>
                                  <span className="txt">Existing</span>
                                </label>
                              </li>
                              {isAppointmentTab ? (
                                <li>
                                  <label className="field_lbl">
                                    <input
                                      type="radio"
                                      name="patientype"
                                      checked={userType === "new"}
                                      onChange={(e) => null}
                                    />
                                    <span className="box_dot"></span>
                                    <span className="txt">New</span>
                                  </label>
                                </li>
                              ) : (
                                <></>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box_patient_reg">
                      <div className="row d-flex align-items-center">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 fieldcol">
                          <div className="field_group">
                            <label className="field_lbl">Patient ID</label>
                          </div>
                        </div>
                        <div className="col-xxl-9 col-xl-9 col-lg-9 fieldcol">
                          <div className="field_group">
                            <input
                              type="text"
                              className="field_input"
                              onChange={(e) => null}
                              value={mainUserProfile?.clinic_patient_id}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box_patient_reg">
                      <div className="row d-flex align-items-center">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 fieldcol">
                          <div className="field_group">
                            <label className="field_lbl">Mobile No</label>
                          </div>
                        </div>
                        <div className="col-xxl-9 col-xl-9 col-lg-9 fieldcol">
                          <div className="field_group">
                            <input
                              type="text"
                              className="field_input"
                              onChange={(e) => null}
                              value={mainUserProfile?.mobile}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box_patient_reg">
                      <div className="row d-flex align-items-center">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 fieldcol">
                          <div className="field_group">
                            <label className="field_lbl">Name</label>
                          </div>
                        </div>
                        <div className="col-xxl-9 col-xl-9 col-lg-9 fieldcol">
                          <div className="field_group">
                            <input
                              type="text"
                              className="field_input"
                              onChange={(e) => null}
                              value={mainUserProfile?.name}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 gridcol">
                  <div className="right_patient_reg">
                    <div className="box_patient_reg">
                      <h4 className="maintitle_block mb-0">
                        Your preferred doctor
                      </h4>
                      <div className="mb-2">
                        <PreferredDoctors
                          enabledDoctors={
                            userProfile.healthCareProviderType === "Dietitian"
                              ? [userProfile.doctorId]
                              : mainUserProfile?.doctorId
                              ? [mainUserProfile!!.doctorId]
                              : [userProfile.doctorId]
                          }
                          isCreateNewPatient={false}
                          preferedDoctor={null}
                          setPreferedDoctor={null}
                        />
                      </div>
                      <UpcomingVisitSummary />
                    </div>
                  </div>
                </div>
              </div>
              {showUploadDialog &&
                mainUserProfile?.clinic_patient_id !== null && (
                  <UploadReportDialog
                    closeFn={() => setShowUploadDialog(false)}
                    patientId={mainUserProfile?.clinic_patient_id!!}
                    isFromAppointment={false}
                  />
                )}
            </div>
          </section>
        </div>

        <div className="actions clearfix">
          <ul role="menu" aria-label="Pagination">
            <li aria-hidden="false" aria-disabled="false">
              <span onClick={() => previousStep()}>Back</span>
            </li>
            {isAppointmentTab ? (
              <li aria-hidden="false" aria-disabled="false">
                <a
                  className={isNextDisabled ? "btn disabled" : "btn"}
                  role="button"
                  aria-disabled="true"
                >
                  <span
                    onClick={() => {
                      nextStep();
                    }}
                  >
                    Next
                  </span>
                </a>
              </li>
            ) : (
              <li aria-hidden="false" aria-disabled="false">
                <a className={false ? "btn disabled" : "btn"} role="button">
                  <span onClick={() => setShowUploadDialog(true)}>
                    Upload Reports
                  </span>
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  };

  const Step3 = () => {
    const { nextStep, previousStep } = useWizard();
    const navigate = useNavigate();
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [calendarEventsRaw, setCalendarEventsRaw] = useState([]);
    const today = new Date().toISOString().split("T")[0];
    let userProfile = useExistingUser(
      searchParams.get("pid") ?? ""
        ? {
            patientId: searchParams.get("pid") ?? "",
          }
        : undefined
    );
    setOldAppointmentId(searchParams.get("id") ?? "");

    let appointmentSlots = useAppointmentSlots({
      days: "60",
      spaceId: receptionistProfile.clinicId,
      ownerId: utils.getDoctorProfileForAppointmentBooking().id,
      fromDate: today,
      reschedule: searchParams.get("reschedule") ? "true" : "false", //If query params contain w, It is an indication for reschedule
      attendeeId:
        mainUserProfile?.clinic_patient_id ?? searchParams.get("pid") ?? "",
    });

    useEffect(() => {
      let dates = appointmentSlots?.resource?.dates;
      if (dates) {
        let availableDates = dates.filter((date) => {
          return (
            date.available ||
            date.buffer_available ||
            (date.slots && date.slots.length > 0)
          );
        });
        let eventDates =
          availableDates
            ?.map((date) => {
              return date.date;
            })
            .map((dateString) => {
              return {
                groupId: "available",
                start: dateString,
                end: dateString,
                display: "inverse-background",
              };
            }) || [];
        // @ts-ignore
        setCalendarEvents(eventDates);
        // @ts-ignore
        setCalendarEventsRaw(dates);
      }
    }, [appointmentSlots.resource]);

    if (appointmentSlots.isLoading) {
      return <h1>Loading...</h1>;
    }
    if (appointmentSlots.error) {
      return (
        <Alert severity="error">{JSON.stringify(appointmentSlots.error)}</Alert>
      );
    }
    const handleDateClick = (arg: any) => {
      let isValidDate = calendarEvents.some((event: any) => {
        return event.start === arg?.dateStr;
      });
      if (isValidDate) {
        if (searchParams.get("w")) {
          setMainUserProfile(userProfile?.resource?.profiles[0]);
        }
        setSelectedDate(arg?.dateStr);
        nextStep();
      }
    };

    function padTo2Digits(num: number): string {
      return num.toString().padStart(2, "0");
    }

    function formatDate(date: Date): string {
      return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-");
    }

    function getRawEvent(date: string): AppointmentDate {
      // @ts-ignore
      return calendarEventsRaw.find((e) => e.date == date);
    }

    const dayCell = (arg: any) => {
      const _date = formatDate(arg.date);
      const _eventDetails = getRawEvent(_date);
      let _cssClass = "cd-slots-nostyle";
      if (_eventDetails && _eventDetails.slots.length > 0) {
        if (_eventDetails.available) {
          _cssClass = "cd-slots-available";
        } else if (!_eventDetails.available && _eventDetails.buffer_available) {
          _cssClass = "cd-slots-almost-full";
        } else {
          _cssClass = "cd-slots-full";
        }
      }
      let _html = `<div class="cd-slot-container"><div class="cd-slots-default ${_cssClass}">${arg.dayNumberText}</div></div>`;
      return { html: _html };
    };

    return (
      <div className="third_step">
        <div className="content clearfix">
          <section>
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="calendar_block" id="kt_calendar">
                  <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    selectable={true}
                    initialView="dayGridMonth"
                    dayCellContent={dayCell}
                    showNonCurrentDates={true}
                    themeSystem={"bootstrap5"}
                    eventShortHeight={30}
                    aspectRatio={3}
                    events={calendarEvents}
                    eventBackgroundColor={"#dddddd"}
                    eventOverlap={false}
                    nowIndicator={false}
                    dateClick={handleDateClick}
                    headerToolbar={{
                      start: "prev", // will normally be on the left. if RTL,
                      // will be on the right
                      center: "title",
                      end: "next", // will normally be on the right. if RTL,
                      // will be on the left
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="actions clearfix">
          <ul role="menu" aria-label="Pagination">
            <li aria-hidden="false" aria-disabled="false">
              <span
                onClick={() => {
                  if (oldAppointmentId) {
                    navigate(-1);
                  } else {
                    previousStep();
                  }
                }}
              >
                Back
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const Step4 = () => {
    let appointmentSlots = useAppointmentSlots({
      days: "1",
      spaceId: receptionistProfile.clinicId,
      ownerId: utils.getDoctorProfileForAppointmentBooking().id,
      fromDate: selectedDate,
      reschedule: searchParams.get("reschedule") ? "true" : "false", //If query params contain w, It is an indication for reschedule
      attendeeId:
        mainUserProfile?.clinic_patient_id ?? searchParams.get("pid") ?? "",
    });
    const { goToStep, nextStep, previousStep } = useWizard();

    if (appointmentSlots.isLoading) {
      return <h1>Loading...</h1>;
    }

    if (appointmentSlots.error) {
      return (
        <Alert severity="error">{JSON.stringify(appointmentSlots.error)}</Alert>
      );
    }
    const getSlotClass = (slot: AppointmentDateSlot) => {
      if (!slot.available) {
        return "disabled";
      }
      if (slot.slot_type === "Normal") {
        return "active";
      } else {
        return "current";
      }
    };

    return (
      <div className="fourth_step">
        <div className="content clearfix">
          <section>
            <ul className="list-unstyled list_timeslot">
              {appointmentSlots?.resource?.dates[0].slots.map(
                (slot, slotIndex) => {
                  return (
                    <li key={slotIndex}>
                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          setSelectedTimeSlot(slot);
                          nextStep();
                        }}
                        className={getSlotClass(slot)}
                      >
                        <span className="txt">{slot.time}</span>
                      </a>
                    </li>
                  );
                }
              )}
            </ul>
          </section>
        </div>

        <div className="actions clearfix">
          <ul role="menu" aria-label="Pagination">
            <li aria-hidden="false" aria-disabled="false">
              <span onClick={() => previousStep()}>Back</span>
            </li>

            <li aria-hidden="false" aria-disabled="false">
              {appointmentSlots?.resource?.dates[0].available ||
              appointmentSlots?.resource?.dates[0].buffer_available ? (
                <span className="btn disabled">Add to Waiting List</span>
              ) : (
                <span
                  onClick={() => {
                    goToStep(5);
                  }}
                >
                  Add to Waiting List
                </span>
              )}
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const Step5 = () => {
    const spaceId = receptionistProfile.clinicId;
    const navigate = useNavigate();
    const { previousStep } = useWizard();
    // const { submit, isLoading, error } = useCreateAppointment("CC000001");
    const { setSnackbar } = useSnackbarContext();
    // ownerId=DR000001 oldAppointmentId
    return (
      <div className="fifth_step">
        <div className="content clearfix">
          <section>
            <div className="patient_reg_block">
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-8 gridcol">
                  <AppointmentSummary
                    mainUserProfile={mainUserProfile!}
                    pid={searchParams.get("pid") ?? ""}
                    appointmentDate={selectedDate}
                    doctorName={
                      utils.getDoctorProfileForAppointmentBooking().name
                    }
                    clinic={new SpaceMetadata(userProfile.clinicName)}
                    time={selectedTimeSlot ? selectedTimeSlot.time : ""}
                    attendeeStatus={undefined}
                    isOnline={false}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="actions clearfix">
          <ul role="menu" aria-label="Pagination">
            <li aria-hidden="false" aria-disabled="false">
              <span onClick={() => previousStep()}>Back</span>
            </li>
            <li
              className={"d-flex flex-row"}
              aria-hidden="false"
              aria-disabled="false"
            >
              <span
                className={"me-2"}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </span>
              <span
                onClick={() => {
                  if (oldAppointmentId) {
                    HttpClient.performPut<any>(
                      `/api/v1/appointments/${oldAppointmentId}/reschedule?slotId=${
                        selectedTimeSlot?.id
                      }&ownerId=${
                        utils.getDoctorProfileForAppointmentBooking().id
                      }`,
                      {
                        online:
                          userProfile.healthCareProviderType === "Dietitian",
                      }
                    )
                      .then((response) => {
                        console.log("response", response);
                        if (!response?.body?.status?.success) {
                          setSnackbar({
                            message: response?.body?.status?.message,
                            severity: "error",
                          });
                        } else {
                          setSnackbar({
                            message: "Appointment rescheduled successfully!",
                            severity: "success",
                          });
                          navigate("/today-appointment", { replace: false });
                        }
                      })
                      .catch((e) => {
                        console.log(e);
                      })
                      .finally(() => {
                        console.log("finally!");
                      });
                  } else {
                    HttpClient.performPost<any>(
                      `/api/v1/appointments/${spaceId}`,
                      {
                        slotId: selectedTimeSlot?.id!,
                        attendeeId:
                          mainUserProfile?.clinic_patient_id ??
                          searchParams.get("pid") ??
                          "",
                        ownerId:
                          utils.getDoctorProfileForAppointmentBooking().id,
                        online:
                          userProfile.healthCareProviderType === "Dietitian",
                      }
                    )
                      .then((response) => {
                        console.log("response", response);
                        if (!response?.body?.status?.success) {
                          setSnackbar({
                            message: response?.body?.status?.message,
                            severity: "error",
                          });
                        } else {
                          setSnackbar({
                            message: "Appointment booked successfully!",
                            severity: "success",
                          });
                          navigate("/today-appointment", { replace: false });
                        }
                      })
                      .catch((e) => {
                        console.log(e);
                      })
                      .finally(() => {
                        console.log("finally!");
                      });
                  }
                }}
              >
                Submit
              </span>
            </li>
          </ul>
          <div className="text-end">
            (Send appointment time and address to patient)
          </div>
        </div>
      </div>
    );
  };

  const Step6 = () => {
    const spaceId = receptionistProfile.clinicId;
    const navigate = useNavigate();
    const { goToStep } = useWizard();
    // const { submit, isLoading, error } = useCreateAppointment("CC000001");
    const { setSnackbar } = useSnackbarContext();
    // ownerId=DR000001 oldAppointmentId
    const [note, setNote] = useState<string>("Missed Appointment");
    const [location, setLocation] = useState<string>("");
    const noteList = [
      "Missed Appointment",
      "Flair Up",
      "Ready to come at anytime",
    ];

    return (
      <div className="sixth_step">
        <div className="content clearfix">
          <section>
            <div className="patient_reg_block">
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-8 gridcol">
                  <div className="left_patient_reg">
                    <div className="box_patient_reg">
                      <div className="row d-flex align-items-center">
                        <div className="big_headtitle mb-2">
                          Add to Waiting List
                        </div>
                      </div>
                    </div>
                    <div className="box_patient_reg">
                      <div className="row d-flex align-items-center">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-5 fieldcol">
                          <span className="txt_field">Patient ID :</span>
                        </div>
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-7 fieldcol">
                          <span className="txt_field fw-bolder">
                            {mainUserProfile?.clinic_patient_id ??
                              searchParams.get("pid") ??
                              ""}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="box_patient_reg">
                      <div className="row d-flex align-items-center">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-5 fieldcol">
                          <span className="txt_field field_title">
                            Patient Name :
                          </span>
                        </div>
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-7 fieldcol">
                          <span className="txt_field fw-bolder">
                            {mainUserProfile?.name}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="box_patient_reg">
                      <div className="row d-flex align-items-center">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-5 fieldcol">
                          <span className="txt_field">Doctor :</span>
                        </div>
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-7 fieldcol">
                          <span className="txt_field fw-bolder">
                            {receptionistProfile.associatedHealthCareProviders.find(
                              (healthcareProvider) =>
                                healthcareProvider.id ===
                                mainUserProfile?.doctorId
                            )?.name || receptionistProfile.doctorName}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="box_patient_reg">
                      <div className="row d-flex align-items-center">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-5 fieldcol">
                          <span className="txt_field">Clinic :</span>
                        </div>
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-7 fieldcol">
                          <span className="txt_field fw-bolder">
                            {receptionistProfile.clinicName}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="box_patient_reg">
                      <div className="row d-flex align-items-center">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-5 fieldcol">
                          <span className="txt_field">Date :</span>
                        </div>
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-7 fieldcol">
                          <span className="txt_field fw-bolder">
                            {moment(new Date(selectedDate)).format(
                              "Do MMM, dddd, YYYY"
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="box_patient_reg">
                      <div className={"row"}>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 ">
                          <FormControl fullWidth>
                            <InputLabel htmlFor={"location-outlined"}>
                              Location/Patient Coming from
                            </InputLabel>
                            <Input
                              id="location-outlined"
                              placeholder={"Location"}
                              autoComplete={"off"}
                              value={location}
                              onChange={(e) => setLocation(e.target.value)}
                              startAdornment={
                                <InputAdornment position="start">
                                  <MdOutlineSearch />
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 ">
                          <FormControl fullWidth>
                            <InputLabel id="note-outlined-label">
                              Note
                            </InputLabel>
                            <Select
                              labelId="note-outlined-label"
                              id="note-outlined"
                              value={note}
                              onChange={(e) => {
                                setNote(e.target.value as string);
                              }}
                              label="Note"
                            >
                              {noteList.map((note) => {
                                return (
                                  <MenuItem key={note} value={note}>
                                    {note}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="actions clearfix">
          <ul role="menu" aria-label="Pagination">
            <li aria-hidden="false" aria-disabled="false">
              <span onClick={() => goToStep(3)}>Back</span>
            </li>
            <li
              className={"d-flex flex-row"}
              aria-hidden="false"
              aria-disabled="false"
            >
              <span
                className={"me-2"}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </span>
              <span
                onClick={() => {
                  HttpClient.performPost<any>(`/api/v1/waiting/${spaceId}`, {
                    date: moment(new Date(selectedDate)).format("YYYY-MM-DD"), //.toLocaleString().split(",")[0].split("/").reverse().join("-"),
                    attendeeId:
                      mainUserProfile?.clinic_patient_id ??
                      searchParams.get("pid") ??
                      "",
                    ownerId:
                      mainUserProfile?.doctorId || receptionistProfile.doctorId,
                    location: location,
                    note: note,
                  })
                    .then((response) => {
                      console.log("response", response);
                      if (!response?.body?.status?.success) {
                        setSnackbar({
                          message: response?.body?.status?.message,
                          severity: "error",
                        });
                      } else {
                        setSnackbar({
                          message: "Added to waiting list successfully!",
                          severity: "success",
                        });
                        navigate("/today-appointment", { replace: false });
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    })
                    .finally(() => {
                      console.log("finally!");
                    });
                }}
              >
                Confirm
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <PageWrapper>
      <div className="main_content">
        <div className="container-fluid">
          <div className="tab_wrapper">
            <div style={{ marginTop: "3em" }} className={classes.root}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                indicatorColor="primary"
              >
                <Tab
                  className="custom_tab_header"
                  label="Registration"
                  disabled
                  {...a11yProps(0)}
                />
                <Tab
                  className="custom_tab_header"
                  label="Health Info"
                  disabled
                  {...a11yProps(1)}
                />
                <Tab
                  className="custom_tab_header"
                  label="Appointment"
                  {...a11yProps(2)}
                />
                <Tab
                  className="custom_tab_header"
                  label="Reports"
                  {...a11yProps(3)}
                />
              </Tabs>
              <div className="tab_body_block">
                <div
                  className="custom_tab_content tab-content"
                  id="pills-tabContent"
                >
                  <TabPanel value={value} index={0}>
                    Registration
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    Health Info
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <div className="tab-pane fade show active" id="appointment">
                      <div className="appoinment_step_block">
                        <div className="appointment_step" id="appointment_step">
                          <Wizard startIndex={0}>
                            <Step1 />
                            <Step2 />
                            <Step3 />
                            <Step4 />
                            <Step5 />
                            <Step6 />
                          </Wizard>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <Reports />
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
