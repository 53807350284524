import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle, IconButton,
} from '@material-ui/core';
import Appointment from '../../models/domain/Appointment';
import { GrClose } from 'react-icons/gr';

interface PatientInformationDialog {
  appointment: Appointment,
  closeFn: () => void
}

export default function PatientInformationDialog ({
  appointment,
  closeFn,
}: PatientInformationDialog) {
  return (
    <>
      <Dialog
        open={true}
        onClose={() => {closeFn();}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {appointment.attendee_metadata.name}
          <IconButton
            aria-label="close"
            onClick={closeFn}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <GrClose/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ minWidth: 320 }}>
              <div>Patient ID: {appointment.attendee_metadata.app_id ??
                appointment.attendee_metadata.clinic_id ??
                ''}</div>
              <div>Mobile number: {appointment.attendee_metadata.mobile ??
                ''}</div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
