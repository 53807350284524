import ExistingUserResponse from '../models/serviceApi/ExistingUserResponse'
import ExistingUser from '../models/domain/ExistingUser'
import ProfileMapper from './ProfileMapper'

export default class ExistingUserMapper {
  static mapResponseToModel(response: ExistingUserResponse): ExistingUser {
    return new ExistingUser(
      response.profiles.map(ProfileMapper.mapResponseToModel),
      response.status,
    );
  }
}
