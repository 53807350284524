import React, { useState } from "react";
import useAppointmentList from "../../controllers/appointments/useAppointmentList";
import { Alert } from "@material-ui/lab";
import { Link, useSearchParams } from "react-router-dom";
import CancelAppointmentDialog from "./CancelAppointmentDialog";
import AppointmentInfo from "../../models/domain/AppointmentInfo";
import utils from "../../Utils";
import Appointment from "../../models/domain/Appointment";
import PatientInformationDialog from "./PatientInformationDialog";
import HealthCareProviderProfile from "../../models/domain/HealthCareProviderProfile";
import { IoVideocam } from "react-icons/io5";

export default function UpcomingAppointmentTable() {
  let [searchParams, setSearchParams] = useSearchParams();
  const dateQueryParamKey = "date";
  const dtSelected = searchParams.get(dateQueryParamKey);
  const [selectedDate, setSelectedDate] = useState(
    dtSelected
      ? dtSelected
      : new Date(new Date().setDate(new Date().getDate() + 1))
          .toISOString()
          .split("T")[0]
  );
  const [minDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
      .toISOString()
      .split("T")[0]
  );
  const [doctorProfile, setDoctorProfile] = useState<HealthCareProviderProfile>(
    utils.retrieveSelectDoctorProfile()
  );
  const upcomingAppointments = useAppointmentList({
    date: selectedDate,
    ownerId: doctorProfile.id,
  });
  const [isCancelDialogVisible, setCancelDialogVisibility] =
    React.useState(false);
  const [selectedAppointment, setSelectedAppointment] =
    React.useState<AppointmentInfo>({
      id: "",
      patientName: "",
    });
  const [appointment, setAppointment] = React.useState<Appointment>();
  const [
    isPatientInformationDialogVisible,
    setIsPatientInformationDialogVisible,
  ] = React.useState(false);

  const openCancelDialog = (
    event: React.MouseEvent<HTMLElement>,
    appointment: AppointmentInfo
  ) => {
    event.preventDefault();
    setSelectedAppointment(appointment);
    setCancelDialogVisibility(true);
  };

  const closeCancelDialog = (toRefresh: boolean = false) => {
    if (toRefresh) {
      const _ = upcomingAppointments.refresh();
    }
    setCancelDialogVisibility(false);
  };

  const print = () => window.print();

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(
      dateQueryParamKey,
      encodeURIComponent(evt.target.value)
    );
    setSearchParams(newSearchParams, {});
    setSelectedDate(evt.target.value);
  };

  if (upcomingAppointments.isLoading) {
    return <h1>Loading...</h1>;
  }

  if (upcomingAppointments.error) {
    return (
      <Alert severity="error">
        {JSON.stringify(upcomingAppointments.error)}
      </Alert>
    );
  }

  return (
    <>
      <div className="main_content">
        <div className="container-fluid">
          <div className="inner_content p-0">
            <h3 className="subtitle_block p-3">Appointment - Upcoming</h3>
            <div className="event_block">
              <div className="row px-3">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 gridcol">
                  <div className="row d-flex align-items-center mb-5">
                    <div className="col-xxl-1 col-xl-2 col-lg-2 col-md-2 gridcol">
                      <span className="txt_field">Select Date</span>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 gridcol">
                      <input
                        type="date"
                        className="field_input"
                        min={minDate}
                        value={selectedDate}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-xxl-9 col-xl-8 col-lg-8 col-md-7 gridcol text-end">
                      <a
                        href="javascript:void(0)"
                        onClick={print}
                        className="link_printer">
                        <span className="fa fa-print"></span>
                        <span className="txt">Print Appointment</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="appointment_block">
                <div className="appointment_body">
                  <div className="table-responsive">
                    <table className="custom_table table">
                      <thead>
                        <tr>
                          <th className="text-center">
                            <span className="txt">Sr. no.</span>
                          </th>
                          <th className="px-1"></th>
                          <th>
                            <span className="txt">Time</span>
                          </th>
                          <th>
                            <span className="txt">Patient Name</span>
                          </th>
                          <th>
                            <span className="txt">Type</span>
                          </th>
                          <th className="text-center th_last_col">
                            <span className="txt fw-bold">
                              Appointment Cancel
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {upcomingAppointments.resource?.appointments?.map(
                          (appointment, index) => {
                            return (
                              <tr
                                // enabling this highlights new appointment coming from website
                                // className={
                                //   appointment.attendee_type == "New" &&
                                //   appointment.source == "WEB"
                                //     ? "highlighted_appointment"
                                //     : ""
                                // }
                                key={appointment.id}>
                                <td className="text-center">
                                  <span className="txt">{index + 1}</span>
                                </td>
                                <td className="px-1">
                                  {appointment.isOnline && <IoVideocam />}
                                </td>
                                <td>
                                  <span className="txt">
                                    {appointment.start_time}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className="txt txt_cursor"
                                    onClick={() => {
                                      setAppointment(appointment);
                                      setIsPatientInformationDialogVisible(
                                        true
                                      );
                                    }}>
                                    {appointment.attendee_metadata.name}
                                  </span>
                                </td>
                                <td>
                                  <span className="txt">
                                    {appointment.attendee_type}
                                  </span>
                                </td>
                                <td className="text-center td_last_col">
                                  <Link
                                    to={`/app-help?w=2&id=${
                                      appointment.id
                                    }&pid=${utils.getPatientId(
                                      appointment
                                    )}&reschedule=true`}
                                    className="link_cancel me-3">
                                    Reschedule
                                  </Link>
                                  <a
                                    onClick={(
                                      e: React.MouseEvent<HTMLElement>
                                    ) => {
                                      openCancelDialog(e, {
                                        id: appointment.id,
                                        patientName:
                                          appointment.attendee_metadata.name,
                                      });
                                    }}
                                    className="link_cancel"
                                    style={{ cursor: "pointer" }}>
                                    Cancel
                                  </a>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                    <CancelAppointmentDialog
                      appointmentInfo={selectedAppointment}
                      isOpen={isCancelDialogVisible}
                      closeFn={closeCancelDialog}
                    />
                    {isPatientInformationDialogVisible && appointment && (
                      <PatientInformationDialog
                        appointment={appointment}
                        closeFn={() => {
                          setIsPatientInformationDialogVisible(false);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
