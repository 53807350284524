import { useMemo } from 'react'
import RemoteResource from '../remoteResource/RemoteResource'
import useRemoteResource from '../remoteResource/useRemoteResource'
import Path, { StringMap } from '../../models/ui/Path'
import AppointmentSlots from '../../models/domain/AppointmentSlots'
import AppointmentSlotsResponse from '../../models/serviceApi/AppointmentSlotsResponse'
import AppointmentSlotsMapper from '../../mappers/AppointmentSlotsMapper'

export default function useAppointmentSlots(query: StringMap = {}): RemoteResource<AppointmentSlots> {
    const { error, isLoading, resource, refresh } = useRemoteResource<AppointmentSlotsResponse>(
        (query !== undefined && query.hasOwnProperty("attendeeId") && query["attendeeId"] !== "" )
        ? new Path(['api', 'v1', 'appointments', 'slots'], query).toString()
        : null,
    );

    const mappedResource = useMemo(
      () =>
        resource ? AppointmentSlotsMapper.mapResponseToModel(resource) : undefined,
      [resource]
    );

    return {
        resource: mappedResource,
        isLoading,
        error,
        refresh,
    };
}
