import PageWrapper from '../components/layout/PageWrapper'
import DoctorSessionsGrid from "../components/appointments/DoctorSessionsGrid";

export default function DoctorSessionsPage() {
    return (
        <PageWrapper>
            <DoctorSessionsGrid />
        </PageWrapper>
    )

}
