import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  OutlinedInput,
  Button,
  Paper,
  Grid,
  Link,
} from "@material-ui/core";
import { ReactComponent as Logo } from "../img/ic4-logo.svg";
import { useSnackbarContext } from "../contexts/Snackbar";
import HttpClient, { HttpMutationOutput } from "../lib/HttpClient";
import { useWizard, Wizard } from "react-use-wizard";
import { useNavigate } from "react-router-dom";
import { PinInput } from "react-input-pin-code";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 40,
    },
    paper: {
      alignItems: "center",
      justifyContent: "center",
      padding: 20,
      height: 480,
      width: 550,
    },
    title: {
      fontSize: 30,
    },
    label: {
      fontSize: 12,
      padding: 10,
    },
    input: {
      backgroundColor: "#ffffff",
      width: 300,
      height: 40,
    },
    button: {
      backgroundColor: "#F78F2D",
      color: "#ffffff",
      flex: 1,
      marginLeft: 85,
    },
    leftMargin: {
      marginLeft: 190,
    },
    rightMargin: {
      marginRight: 80,
    },
    linkSmall: {
      color: "#0E426A",
      fontSize: 14,
    },
    linkSmallDisabled: {
      color: "#808080",
      fontSize: 14,
    },
    timer: {
      fontSize: 12,
      padding: 10,
      color: "#F78F2D",
    },
  })
);

interface State {
  email: string;
  tid: string;
  otp: string;
  password: string;
  conformationPassword: string;
  emailHidden: string;
}

export default function ResetPasswordPage() {
  const classes = useStyles();
  const { setSnackbar } = useSnackbarContext();
  const [values, setValues] = React.useState<State>({
    email: "",
    tid: "",
    otp: "",
    password: "",
    conformationPassword: "",
    emailHidden: ""
  });
  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  const navigate = useNavigate();

  const StepOne = () => {
    const { nextStep, previousStep } = useWizard();
    const [email, setEmail] = React.useState("");
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
      values.email = event.target.value;
    };
    function sendOTP() {
      if (values.email.length !== 0) {
        HttpClient.performPost(`/api/v1/clinics/receptionist/forget/otp`, {
          email: values.email,
        })
          .then((response: HttpMutationOutput<any>) => {
            if (!response?.body?.status?.success) {
              setSnackbar({
                message: response?.body?.status?.message,
                severity: "error",
              });
            } else {
              console.log(response?.body?.tid);
              values.tid = response?.body?.tid;
              values.emailHidden = response?.body?.email;
              nextStep();
            }
          })
          .catch((e: any) => {
            setSnackbar({
              message: "Error occurred while requesting OTP. " + e.message,
              severity: "error",
            });
          });
      } else {
        setSnackbar({
          message: "Please enter Email ID",
          severity: "error",
        });
      }
    }

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={5}
        className={classes.root}
      >
        <Grid item xs={8}>
          <label className={classes.label}>What is your Email ID?</label>
          <OutlinedInput
            className={classes.input}
            id="email"
            key="email"
            value={email}
            onChange={handleEmailChange}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={sendOTP}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    );
  };
  const StepTwo = () => {
    const { nextStep, previousStep } = useWizard();
    const [pins, setPins] = React.useState(["", "", "", "", "", ""]);
    // timer
    const durationInSeconds = 45;
    let resendTimer: NodeJS.Timeout | any = undefined;
    const [isResendOTPBlocked, setIsResentOTPBlocked] = React.useState(true);
    let [resendCounter, setResendCounter] = React.useState(durationInSeconds);
    const [waitText, setWaitText] = React.useState("");

    useEffect(() => {
      // Init
      resendTimer = setInterval(resendOTPBlocker, 1000);
      return () => {
        resendTimer = undefined;
      };
    }, []);

    const resendOTPBlocker = () => {
      setIsResentOTPBlocked(true);
      const _timeUnit = resendCounter === 1 ? "second" : "seconds";
      setWaitText(`Resend OTP in ${resendCounter} ${_timeUnit}`);
      setResendCounter(resendCounter--);
      if (resendCounter === 0) {
        setIsResentOTPBlocked(false);
        setResendCounter(durationInSeconds);
        setWaitText("");
        if (resendTimer !== undefined) clearInterval(resendTimer);
      }
    };

    function verifyOTP() {
      if (values.otp.length == 6) {
        HttpClient.performPost(
          `/api/v1/clinics/receptionist/forget/otp/verify`,
          {
            otp: values.otp,
            tid: values.tid,
            email: values.email,
          }
        )
          .then((response: HttpMutationOutput<any>) => {
            if (!response?.body?.status?.success) {
              setSnackbar({
                message: response?.body?.status?.message,
                severity: "error",
              });
            } else {
              console.log(response?.body);
              nextStep();
            }
          })
          .catch((e: any) => {
            setSnackbar({
              message: "Error occurred while verifying OTP. " + e.message,
              severity: "error",
            });
          });
      } else {
        setSnackbar({
          message: "Invalid OTP",
          severity: "error",
        });
      }
    }

    function resendOTP() {
      HttpClient.performPost(`/api/v1/clinics/receptionist/forget/otp`, {
        email: values.email,
      })
        .then((response: HttpMutationOutput<any>) => {
          if (!response?.body?.status?.success) {
            setSnackbar({
              message: response?.body?.status?.message,
              severity: "error",
            });
          } else {
            console.log(response?.body?.tid);
            values.tid = response?.body?.tid;
            values.emailHidden = response?.body?.email;
            resendTimer = setInterval(resendOTPBlocker, 1000);
          }
        })
        .catch((e: any) => {
          setSnackbar({
            message: "Error occurred while requesting OTP. " + e.message,
            severity: "error",
          });
        });
    }

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={4}
        className={classes.root}
      >
        <Grid item xs={9}>
          <label className={classes.label}>Please enter OTP</label>
          <label className={classes.label}>
            (OTP has been sent to {values.emailHidden})
          </label>
        </Grid>
        <Grid item xs={8}>
          <PinInput
            values={pins}
            type={"number"}
            borderColor={"rgb(247, 143, 45)"}
            onChange={(pin, index, pins) => {
              setPins(pins);
              values.otp = pins.toString().replaceAll(",", "");
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <Link
            className={classes.linkSmall}
            onClick={() => {
              setPins(["", "", "", "", "", ""]);
              values.otp = "";
            }}
          >
            Clear
          </Link>
          <Link
            className={[
              isResendOTPBlocked
                ? classes.linkSmallDisabled
                : classes.linkSmall,
              classes.leftMargin,
            ].join(" ")}
            onClick={isResendOTPBlocked ? () => {} : resendOTP}
          >
            Resend
          </Link>
        </Grid>
        <Grid item xs={8}>
          <span className={classes.timer}>{waitText}</span>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            className={[classes.button].join(" ")}
            onClick={verifyOTP}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    );
  };
  const StepThree = () => {
    const { nextStep, previousStep } = useWizard();
    const [password, setPassword] = React.useState("");
    const handlePasswordChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setPassword(event.target.value);
      values.password = event.target.value;
    };
    const [confPassword, setConfPassword] = React.useState("");

    const handleConfPasswordChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setConfPassword(event.target.value);
      values.conformationPassword = event.target.value;
    };

    function resetPassword() {
      if (values.password === values.conformationPassword) {
        HttpClient.performPost(`/api/v1/clinics/receptionist/password`, {
          tid: values.tid,
          email: values.email,
          password: values.password,
        })
          .then((response: HttpMutationOutput<any>) => {
            if (!response?.body?.status?.success) {
              setSnackbar({
                message: response?.body?.status?.message,
                severity: "error",
              });
            } else {
              navigate("/");
            }
          })
          .catch((e: any) => {
            setSnackbar({
              message: "Error occurred while verifying OTP. " + e.message,
              severity: "error",
            });
          });
      } else {
        setSnackbar({
          message: "Passwords do not match, please reenter password",
          severity: "error",
        });
      }
    }

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={5}
        className={classes.root}
      >
        <Grid item xs={7}>
          <label className={classes.label}>Enter new password</label>
          <OutlinedInput
            className={classes.input}
            id="password"
            type={"password"}
            value={password}
            onChange={handlePasswordChange}
          />
        </Grid>

        <Grid item xs={7}>
          <label className={classes.label}>Re-enter password</label>
          <OutlinedInput
            className={classes.input}
            id="password-confirm"
            type={"password"}
            value={confPassword}
            onChange={handleConfPasswordChange}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={resetPassword}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={5}
      className={classes.root}
    >
      <Grid item>
        <Logo />
      </Grid>
      <Grid item>
        <Paper className={classes.paper} elevation={0}>
          <Grid container direction="column" alignItems="center" spacing={3}>
            <Grid item xs={12}>
              <label className={classes.title}>Reset Password</label>
            </Grid>
            <Wizard startIndex={0}>
              <StepOne />
              <StepTwo />
              <StepThree />
            </Wizard>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
