import { useMemo } from 'react'
import RemoteResource from '../remoteResource/RemoteResource'
import useRemoteResource from '../remoteResource/useRemoteResource'
import Path, { StringMap } from '../../models/ui/Path'
import UpcomingUserWaitlistResponse from "../../models/serviceApi/UpcomingUserWaitlistResponse";
import UpcomingUserWaitlist from "../../models/domain/UpcomingUserWaitlist";
import UpcomingUserWaitlistMapper from "../../mappers/UpcomingUserWaitlistMapper";

export default function useUpcomingUserWaitlist(query: StringMap = {}): RemoteResource<UpcomingUserWaitlist> {
    const { error, isLoading, resource, refresh } = useRemoteResource<UpcomingUserWaitlistResponse>(
        query !== undefined
            ? new Path(['api', 'v1', 'waiting'], query).toString()
            : null,
    );

    const mappedResource = useMemo(
        () =>
            resource ? UpcomingUserWaitlistMapper.mapResponseToModel(resource) : undefined,
        [resource]
    );

    return {
        resource: mappedResource,
        isLoading,
        error,
        refresh,
    };
}
