import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useState,
} from "react";
import { Modal, Spinner } from "react-bootstrap";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ReceptionistProfile from "../../models/domain/ReceptionistProfile";
import utils from "../../Utils";
import HttpClient from "../../lib/HttpClient";
import { useSnackbarContext } from "../../contexts/Snackbar";
import Appointment from "../../models/domain/Appointment";

interface AddVitalsModalProps {
  isAddVitalsModalOpen: boolean | undefined;
  setIsAddVitalsModalOpen: (isAddVitalsModalOpen: boolean) => void;
  selectedAppointment: any;
  setSelectedAppointment: (appointment: object | undefined) => void;
}

const requestVitalsFormDataSubmission = (
  selectedAppointment: any,
  vitalsData: any
) =>
  HttpClient.performPost<any>(
    `/api/v1/users/vital/${selectedAppointment.id}/`,
    processVitalsFormDataForSubmission(vitalsData)
  );

const processVitalsFormDataForSubmission = (data: any) => {
  return {
    systolic: data.systolic ? `${data.systolic}mmHg` : "",
    diastolic: data.diastolic ? `${data.diastolic}mmHg` : "",
    oxygen: data.oxygen ? `${data.oxygen}%` : "",
    pulse: data.pulse ? `${data.pulse}/min` : "",
    bloodSugar: data.bloodSugar ? `${data.bloodSugar}mg/dL` : "",
  };
};

const processVitalsDataFromApiResponse = (data: any) => {
  return {
    systolic: data.systolic?.replace(/[^0-9.]/g, "") || "",
    diastolic: data.diastolic?.replace(/[^0-9.]/g, "") || "",
    oxygen: data.oxygen?.replace(/[^0-9.]/g, "") || "",
    pulse: data.pulse?.replace(/[^0-9.]/g, "") || "",
    bloodSugar: data.bloodSugar?.replace(/[^0-9.]/g, "") || "",
  };
};

const requestGetVitalsData = (selectedAppointment: any) =>
  HttpClient.performGet<any>(`/api/v1/users/vital/${selectedAppointment.id}/`);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    inputElement: {
      height: "0px",
    },
    textField: {
      width: "280px",
    },

    formRow: {
      width: "400px",
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "10px",
    },
    loadingContainer: {
      minHeight: "224px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

const AddVitalsModal = ({
  isAddVitalsModalOpen: isAddVitalsModalOpen,
  setIsAddVitalsModalOpen: setIsAddVitalsModalOpen,
  selectedAppointment: selectedAppointment,
  setSelectedAppointment: setSelectedAppointment,
}: AddVitalsModalProps) => {
  const classes = useStyles();

  const [formState, setFormState] = useState({
    systolic: "",
    diastolic: "",
    oxygen: "",
    pulse: "",
    bloodSugar: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const { setSnackbar } = useSnackbarContext();

  useEffect(() => {
    selectedAppointment.vital && fetchData();
  }, []);

  const cancelVitalsAndCloseModal = () => {
    setSelectedAppointment(undefined);
    setIsAddVitalsModalOpen(false);
  };

  const submitVitalsData = async () => {
    try {
      const vitalsResponse = await requestVitalsFormDataSubmission(
        selectedAppointment,
        formState
      );
      if (vitalsResponse?.body?.status?.code == "200") {
        setSelectedAppointment(undefined);
        setIsAddVitalsModalOpen(false);
        setSnackbar({
          message: "Vitals update successful!",
          severity: "success",
        });
      } else {
        setSnackbar({
          message: vitalsResponse?.body?.status?.message,
          severity: "error",
        });
      }
    } catch (e) {
      setSnackbar({
        message: "Something went wrong!",
        severity: "error",
      });
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    await getVitalsData();
    setIsLoading(false);
  };

  const getVitalsData = async () => {
    try {
      const vitalsResponse = await requestGetVitalsData(selectedAppointment);
      if (vitalsResponse?.body?.status?.code == "200") {
        vitalsResponse?.body?.vital &&
          setFormState(
            processVitalsDataFromApiResponse(vitalsResponse?.body?.vital)
          );
      } else {
        setSnackbar({
          message: vitalsResponse?.body?.status?.message,
          severity: "error",
        });
      }
    } catch (e) {
      setSnackbar({
        message: "Something went wrong!",
        severity: "error",
      });
    }
  };

  const onFormInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(event);
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  return (
    <Modal
      show={isAddVitalsModalOpen}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton onClick={cancelVitalsAndCloseModal}>
        <Modal.Title>Vitals</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex">
          <div>
            <span className="fw-bold">Patient Name </span>:{" "}
            {selectedAppointment?.attendee_metadata.name}
          </div>
          <div className="mx-4">
            <span className="fw-bold">Patient ID </span>:{" "}
            {selectedAppointment?.attendee_metadata.app_id}
          </div>
        </div>
        {isLoading ? (
          <div className={classes.loadingContainer}>
            <Spinner />
          </div>
        ) : (
          <div className="mt-4 px-4">
            <div className={classes.formRow}>
              <span>Systolic</span>
              <OutlinedInput
                id="systolicInput"
                className={classes.textField}
                classes={{
                  input: classes.inputElement,
                }}
                name="systolic"
                type="number"
                value={formState.systolic}
                onChange={onFormInputChange}
                endAdornment={
                  <InputAdornment position="end">mmHg</InputAdornment>
                }
                aria-describedby="outlined-systolic-helper-text"
                inputProps={{
                  "aria-label": "Systolic",
                }}
              />
            </div>

            <div className={classes.formRow}>
              <span>Diastolic</span>
              <OutlinedInput
                className={classes.textField}
                classes={{
                  input: classes.inputElement,
                }}
                type="number"
                name="diastolic"
                value={formState.diastolic}
                onChange={onFormInputChange}
                endAdornment={
                  <InputAdornment position="end">mmHg</InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
              />
            </div>
            <div className={classes.formRow}>
              <span>Oxygen</span>
              <OutlinedInput
                className={classes.textField}
                classes={{
                  input: classes.inputElement,
                }}
                name="oxygen"
                type="number"
                value={formState.oxygen}
                onChange={onFormInputChange}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
              />
            </div>
            <div className={classes.formRow}>
              <span>Pulse</span>
              <OutlinedInput
                className={classes.textField}
                name="pulse"
                classes={{
                  input: classes.inputElement,
                }}
                value={formState.pulse}
                onChange={onFormInputChange}
                type="number"
                endAdornment={
                  <InputAdornment position="end">/min</InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
              />
            </div>
            <div className={classes.formRow}>
              <span>Blood Sugar</span>
              <OutlinedInput
                id="bloodSugarInput"
                className={classes.textField}
                classes={{
                  input: classes.inputElement,
                }}
                name="bloodSugar"
                type="number"
                value={formState.bloodSugar}
                onChange={onFormInputChange}
                endAdornment={
                  <InputAdornment position="end">mg/dL</InputAdornment>
                }
                aria-describedby="outlined-blood-sugar-helper-text"
                inputProps={{
                  "aria-label": "Blood Sugar",
                }}
              />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn_outline" onClick={cancelVitalsAndCloseModal}>
          Cancel
        </button>
        <button className="btn_primary" onClick={submitVitalsData}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddVitalsModal;
