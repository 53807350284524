import AppointmentSlots from '../models/domain/AppointmentSlots'
import AppointmentSlotsResponse from '../models/serviceApi/AppointmentSlotsResponse'
import AppointmentDateMapper from './AppointmentDateMapper'

export default class AppointmentSlotsMapper {
  static mapResponseToModel(response: AppointmentSlotsResponse): AppointmentSlots {
    return new AppointmentSlots(
      response.dates.map(AppointmentDateMapper.mapResponseToModel),
      response.status
    );
  }
}
