import React, { useEffect, useState } from "react";
import useAppointmentList from "../../controllers/appointments/useAppointmentList";
import { Alert } from "@material-ui/lab";
import PatientInformationDialog from "./PatientInformationDialog";
import Appointment from "../../models/domain/Appointment";
import HealthCareProviderProfile from "../../models/domain/HealthCareProviderProfile";
import utils from "../../Utils";
import { IoVideocam } from "react-icons/io5";

export default function HistoryAppointmentTable() {
  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 1))
      .toISOString()
      .split("T")[0]
  );
  const [maxDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 1))
      .toISOString()
      .split("T")[0]
  );
  const [doctorProfile, setDoctorProfile] = useState<HealthCareProviderProfile>(
    utils.retrieveSelectDoctorProfile()
  );
  const historyAppointments = useAppointmentList({
    date: selectedDate,
    ownerId: doctorProfile.id,
  });
  const [appointment, setAppointment] = React.useState<Appointment>();
  const [
    isPatientInformationDialogVisible,
    setIsPatientInformationDialogVisible,
  ] = React.useState(false);

  useEffect(() => {}, [selectedDate]);

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(evt.target.value);
  };

  if (historyAppointments.isLoading) {
    return <h1>Loading...</h1>;
  }

  if (historyAppointments.error) {
    return (
      <Alert severity="error">
        {JSON.stringify(historyAppointments.error)}
      </Alert>
    );
  }

  return (
    <>
      <div className="main_content">
        <div className="container-fluid">
          <div className="inner_content p-0">
            <h3 className="subtitle_block p-3">Appointment - History</h3>
            <div className="event_block">
              <div className="row px-3">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 gridcol">
                  <div className="row d-flex align-items-center mb-5">
                    <div className="col-xxl-1 col-xl-2 col-lg-2 col-md-2 gridcol">
                      <span className="txt_field">Select Date</span>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 gridcol">
                      <input
                        type="date"
                        className="field_input"
                        max={maxDate}
                        value={selectedDate}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-xxl-9 col-xl-8 col-lg-8 col-md-7 gridcol text-end d-none">
                      <a href="javascript:void(0)" className="link_printer">
                        <span className="fa fa-print"></span>
                        <span className="txt">Print Appointment</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="appointment_block">
                <div className="appointment_body">
                  <div className="table-responsive">
                    <table className="custom_table table">
                      <thead>
                        <tr>
                          <th className="text-center">
                            <span className="txt">Sr. no.</span>
                          </th>
                          <th className="px-1"></th>
                          <th>
                            <span className="txt">Name</span>
                          </th>
                          <th>
                            <span className="txt">Time</span>
                          </th>
                          <th>
                            <span className="txt">Status</span>
                          </th>
                          <th>
                            <span className="txt">Clinic Patient ID</span>
                          </th>
                          <th className="text-center">
                            <span className="txt fw-bold">App Patient ID</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {historyAppointments.resource?.appointments
                          ?.filter((appointment) => {
                            return appointment.source != "DOCTOR";
                          })
                          .map((appointment, index) => {
                            return (
                              <tr
                                // enabling this highlights new appointment coming from website
                                // className={
                                //   appointment.attendee_type == "New" &&
                                //   appointment.source == "WEB"
                                //     ? "highlighted_appointment"
                                //     : ""
                                // }
                                key={appointment.id}>
                                <td className="text-center">
                                  <span className="txt">{index + 1}</span>
                                </td>
                                <td className="px-1">
                                  {appointment.isOnline && <IoVideocam />}
                                </td>
                                <td>
                                  <span
                                    className="txt txt_cursor"
                                    onClick={() => {
                                      setAppointment(appointment);
                                      setIsPatientInformationDialogVisible(
                                        true
                                      );
                                    }}>
                                    {appointment.attendee_metadata.name}
                                  </span>
                                </td>
                                <td>
                                  <span className="txt">
                                    {appointment.start_time}
                                  </span>
                                </td>
                                <td>
                                  <span className="txt">
                                    {appointment.attendee_status}
                                  </span>
                                </td>
                                <td>
                                  <span className="txt">
                                    {appointment.attendee_metadata.clinic_id}
                                  </span>
                                </td>
                                <td className="text-center">
                                  <span className="txt">
                                    {appointment.attendee_metadata.app_id}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {isPatientInformationDialogVisible && appointment && (
                      <PatientInformationDialog
                        appointment={appointment}
                        closeFn={() => {
                          setIsPatientInformationDialogVisible(false);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
