import WaitlistMapper from "./WaitlistMapper";
import UpcomingUserWaitlistResponse from "../models/serviceApi/UpcomingUserWaitlistResponse";
import UpcomingUserWaitlist from "../models/domain/UpcomingUserWaitlist";

export default class UpcomingUserWaitlistMapper {

    static mapResponseToModel(response: UpcomingUserWaitlistResponse): UpcomingUserWaitlist {
        return new UpcomingUserWaitlist(
            response.wait?WaitlistMapper.mapResponseToModel(response.wait):null,
            response.status,
        );
    }
}
