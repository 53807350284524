import React from 'react';
import './styles/App.css';
import TodayAppointmentTable from './components/appointments/TodayAppointmentTable'
import AppRoutes from './components/routes/AppRoutes'
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

//AppRoutes
function App() {
    const customTheme = createTheme({
        palette: {
            primary: {
                light: "#b2dfdb",
                main: "#0060a6",
                dark: "#004d40"
            },
            secondary: {
                main: "#f78f2d"
            }
        }
    });

    return (
        <ThemeProvider theme={customTheme}>
            <div className="App">
                <AppRoutes />
            </div>
        </ThemeProvider>
    );
}

export default App;
