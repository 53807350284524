import { useMemo } from 'react'
import RemoteResource from '../remoteResource/RemoteResource'
import useRemoteResource from '../remoteResource/useRemoteResource'
import Path, { StringMap } from '../../models/ui/Path'
import ExistingUser from '../../models/domain/ExistingUser'
import ExistingUserResponse from '../../models/serviceApi/ExistingUserResponse'
import ExistingUserMapper from '../../mappers/ExistingUserMapper'

export default function useExistingUser(query: StringMap = {}): RemoteResource<ExistingUser> {
    const { error, isLoading, resource, refresh } = useRemoteResource<ExistingUserResponse>(
      query !== undefined
        ? new Path(['api', 'v1', 'users', 'search'], query).toString()
        : null,
    );

    const mappedResource = useMemo(
      () =>
        resource ? ExistingUserMapper.mapResponseToModel(resource) : undefined,
      [resource]
    );

    return {
        resource: mappedResource,
        isLoading,
        error,
        refresh,
    };
}
