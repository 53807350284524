import React from 'react';
import { Alert } from '@material-ui/lab'

export type AlertSeverity = 'error' | 'warning' | 'info' | 'success';

export type AlertComponentProps = React.PropsWithChildren<{
    severity: AlertSeverity;
    onClose?: () => void;
}>;

export default function AlertComponent({
    severity,
    onClose,
    children,
}: AlertComponentProps) {
    return (
        <Alert variant={"filled"} onClose={onClose} severity={severity}>
            {children}
        </Alert>
    );
}
