import useSWR from 'swr';
import RemoteResource from './RemoteResource';
import HttpClient, { HttpGetOutput } from '../../lib/HttpClient'

export default function useRemoteResource<T>(
    getUrl: string | null
): RemoteResource<T> {
    const { data, error, mutate } = useSWR<HttpGetOutput<T>, Error>(
        getUrl,
        HttpClient.performGet
    );

    return {
        resource: data?.body,
        isLoading: !error && !data,
        error: error,
        refresh: async () => {
            await mutate();
        },
    };
}
