import PageWrapper from '../components/layout/PageWrapper'
import HistoryAppointmentTable from '../components/appointments/HistoryAppointmentTable'

export default function UpcomingAppointmentPage() {
  return (
    <PageWrapper>
      <HistoryAppointmentTable />
    </PageWrapper>
  )

}
