import Appointment from "./models/domain/Appointment";
import ReceptionistProfile from "./models/domain/ReceptionistProfile";
import ReceptionistProfileRaw from "./models/domain/ReceptionistProfileRaw";
import HealthCareProviderProfile from "./models/domain/HealthCareProviderProfile";
import HealthCareProviderProfileRaw from "./models/domain/HealthCareProviderProfileRaw";

const getPatientId = (appointment: Appointment) => {
  return appointment.attendee_metadata
    ? appointment.attendee_metadata.app_id
    : null;
};

const getErrorMessage = (stringifiedError: String) => {
  // @ts-ignore
  return JSON.parse(stringifiedError)?.body?.status?.message;
};

const retrieveUserProfile = () => {
  const profileRaw = localStorage.getItem("profile");
  if (!profileRaw) {
    document.location.href = "/";
    return emptyUserProfile;
  } else {
    let parsedProfile = JSON.parse(
      profileRaw || "{}"
    ) as unknown as ReceptionistProfileRaw;
    if (parsedProfile) {
      return new ReceptionistProfile(
        parsedProfile.clinicId,
        parsedProfile.clinicName,
        parsedProfile.doctorId,
        parsedProfile.doctorName,
        parsedProfile.email,
        parsedProfile.mobile,
        parsedProfile.name,
        parsedProfile.healthProviderType,
        parsedProfile.associatedHealthProviders.map((hcpr) => {
          return new HealthCareProviderProfile(
            hcpr.healthProviderId,
            hcpr.healthProviderName,
            hcpr.healthProviderType,
            hcpr.healthProviderSession ? hcpr.healthProviderSession : ""
          );
        })
      );
    } else {
      return parsedProfile;
    }
  }
};

const retrieveSelectDoctorProfile = (storageKey: string = "selectedDoctor") => {
  const doctorProfileRaw = localStorage.getItem(storageKey);
  const receptionistProfile: ReceptionistProfile = retrieveUserProfile();

  if (!doctorProfileRaw) {
    if (
      receptionistProfile &&
      receptionistProfile.associatedHealthCareProviders
    ) {
      const doctor = receptionistProfile.associatedHealthCareProviders.find(
        (healthCareProvider: HealthCareProviderProfile) =>
          healthCareProvider.providerType === "Doctor" ||
          healthCareProvider.providerType === "Dietitian"
      );

      if (doctor) {
        return doctor;
      }
    }
    // Handle the case where no doctor is found
    return defaultDoctor; // or some other appropriate value
  } else {
    return JSON.parse(
      doctorProfileRaw || "{}"
    ) as unknown as HealthCareProviderProfile;
  }
};

const getDoctorProfileForAppointmentBooking = () => {
  const profileRaw = localStorage.getItem(
    "preferredDoctorForAppointmentBooking"
  );
  if (!profileRaw) {
    return retrieveSelectDoctorProfile();
  } else {
    return JSON.parse(
      profileRaw || "{}"
    ) as unknown as HealthCareProviderProfile;
  }
};

const emptyUserProfile = {
  name: "",
  clinicName: "",
  clinicId: "",
  doctorId: "",
  doctorName: "",
  email: "",
  mobile: "",
  healthCareProviderType: "",
  associatedHealthCareProviders: [],
};

const defaultDoctor = new HealthCareProviderProfile(
  "DR000001",
  "Dr. S.M. Akerkar",
  "Doctor",
  ""
);

const mockDoctorConsultationSession = {
  healthCareProvider: defaultDoctor,
  appointmentCount: 80,
  newPatientCount: 20,
  sessionTimingFormatted: "8:00am to 3:00pm",
};

export const isUnauthorised = (responseStatus: any) => {
  return (
    responseStatus &&
    !responseStatus["success"] &&
    responseStatus["code"] === 4011 &&
    responseStatus["message"] === "Unauthorized request"
  );
};

const getEnv = () => {
  if (window.location.hostname === "test.ic4.health") {
    return "staging";
  } else if (window.location.hostname === "ic4.health") {
    return "production";
  } else if (window.location.hostname.indexOf("localhost") !== -1) {
    return "development";
  } else {
    return "unknown";
  }
};

const getAllStorage = () => {
  try {
    const keys = Object.keys(localStorage);
    let toReturn = [];
    for (let i = 0; i < keys.length; i++) {
      const _key = keys[i];
      if (_key != "token") {
        toReturn.push([_key, localStorage.getItem(_key)]);
      }
    }
    return toReturn;
  } catch (e) {
    return [];
  }
};

export const getPerson = () => {
  try {
    return {
      id: localStorage.getItem("profile")
        ? localStorage.getItem("profile")
        : "USER_NOT_AUTHENTICATED",
      props: getAllStorage(),
    };
  } catch (e) {
    return {
      id: "ERR_USER",
      props: [],
    };
  }
};

export const rollbarConfig = {
  accessToken: "ee41f491d20941f29d7ff795fd00c164",
  autoInstrument: true,
  environment: getEnv(),
  server: {
    root: window.location.hostname,
    branch: "main",
  },
  code_version: "1.0.0-react",
  person: localStorage.getItem("profile")
    ? { id: localStorage.getItem("profile") }
    : null,
};

export const rollbarConfigV2 = {
  accessToken: "ee41f491d20941f29d7ff795fd00c164",
  captureUncaught: true,
  captureUnhandledRejections: true,
  autoInstrument: true,
  payload: {
    environment: getEnv(),
    person: getPerson(),
    //trace_id: 'abc',
    client: {
      javascript: {
        code_version: "1.0.0-react",
        source_map_enabled: true,
        //guess_uncaught_frames: true
      },
    },
    server: {
      host: window.location.hostname,
      branch: "main",
    },
    //server: {
    //root: 'http://localhost:8000/demo/',
    //host: 'host-1',
    //branch: 'HEAD',
    //},
  },
};

const _ = {
  getPatientId,
  getErrorMessage,
  retrieveUserProfile,
  retrieveSelectDoctorProfile,
  getDoctorProfileForAppointmentBooking,
  emptyUserProfile,
  mockDoctorConsultationSession,
  defaultDoctor,
  isUnauthorised,
  rollbarConfig,
  rollbarConfigV2,
};

export default _;
