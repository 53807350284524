import PageWrapper from '../components/layout/PageWrapper'
import UpcomingAppointmentTable from '../components/appointments/UpcomingAppointmentTable'

export default function UpcomingAppointmentPage() {
  return (
    <PageWrapper>
      <UpcomingAppointmentTable />
    </PageWrapper>
  )

}
