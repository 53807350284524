import AppointmentDateSlot from '../models/domain/AppointmentDateSlot'
import AppointmentDateSlotResponse from '../models/serviceApi/AppointmentDateSlotResponse'

export default class AppointmentDateSlotMapper {
  static mapResponseToModel(response: AppointmentDateSlotResponse): AppointmentDateSlot {
    return new AppointmentDateSlot(
      response.id,
      response.time,
      response.available,
      response.bookedCount,
      response.slotType,
    );
  }
}
