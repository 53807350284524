export default class AppointmentDateSlot {
  constructor(
    public readonly id: number,
    public readonly time: string,
    public readonly available: boolean,
    public readonly booked_count: number,
    public readonly slot_type: string
  ) {
  }
}
