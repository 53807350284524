
export default class CurrentSessionStats {
    constructor(
        public readonly patient_count: number,
        public readonly new_patient_count: number,
        public readonly existing_patient_count: number,
        public readonly online_consultation_count: number,
        public readonly inperson_consultation_count: number
    ) {
    }
}