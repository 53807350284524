export type StringMap = { [key: string]: string };

export default class Path {
    constructor(
        private readonly parts: string[],
        private readonly queryParams: StringMap = {}
    ) {}

    public toString(): string {
        const encodedParts = this.parts.map((part) => encodeURIComponent(part));

        const queryParts = Object.keys(this.queryParams).map(
            (queryParam) =>
                encodeURIComponent(queryParam) +
                '=' +
                encodeURIComponent(this.queryParams[queryParam])
        );
        const encodedQueryUriPart = queryParts.length
            ? '?' + queryParts.join('&')
            : '';

        return '/' + encodedParts.join('/') + encodedQueryUriPart;
    }
}
