import utils from "../../Utils";
import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import HealthCareProviderProfile from "../../models/domain/HealthCareProviderProfile";

interface preferredDoctorSettings {
  enabledDoctors: string[];
  isCreateNewPatient: boolean;
  preferedDoctor: HealthCareProviderProfile|null;
  setPreferedDoctor: Function|null;
}
export default function PreferredDoctors(props: preferredDoctorSettings) {
  const [userProfile, setUserProfile] = useState(utils.retrieveUserProfile());
  const [doctorProfile, setDoctorProfile] = useState(
    utils.retrieveSelectDoctorProfile()
  );

  const [selectedDoctor, setSelectedDoctor] =
    useState<HealthCareProviderProfile>(
      props.isCreateNewPatient
        ? (props.preferedDoctor as HealthCareProviderProfile)
        : utils.defaultDoctor
    );

  const getEnabledDoctorProfile = (enabledDoctorIds: string[]) => {
    if (enabledDoctorIds.length >= 1) {
      const _profile = userProfile.associatedHealthCareProviders.find(
        // for now we expect only 1 enabled doctor
        (profile) => profile.id === enabledDoctorIds[0]
      );
      if (_profile) {
        return _profile;
      }
    }
    return utils.retrieveSelectDoctorProfile();
  };

  useEffect(() => {
    if (props.enabledDoctors) {
      const _profile = getEnabledDoctorProfile(props.enabledDoctors);
      setSelectedDoctor(_profile);
      localStorage.setItem(
        "preferredDoctorForAppointmentBooking",
        JSON.stringify(_profile)
      );
    }
  }, [props.enabledDoctors]);

  return (
    <>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="noteType"
          name="noteType"
          value={selectedDoctor}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            console.log(event.target.value);
            const _profile = userProfile.associatedHealthCareProviders.find(
              (profile) => profile.id === event.target.value
            );
            console.log(_profile);

            setSelectedDoctor(_profile!);
            props?.setPreferedDoctor?.(_profile);
            localStorage.setItem(
              "preferredDoctorForAppointmentBooking",
              JSON.stringify(_profile || {})
            );
            console.log(selectedDoctor);
          }}
        >
          {userProfile.associatedHealthCareProviders.map((profile, index) => (
            <FormControlLabel
              key={index}
              value={profile.id}
              control={
                props.isCreateNewPatient ? (
                  <Radio checked={props?.preferedDoctor?.id == profile.id} />
                ) : (
                  <Radio checked={props.enabledDoctors.includes(profile.id)} />
                )
              }
              disabled={
                !props.isCreateNewPatient &&
                !props.enabledDoctors.includes(profile.id)
              }
              label={profile.name}
            />
          ))}
        </RadioGroup>
        {!props.isCreateNewPatient &&userProfile.associatedHealthCareProviders.filter((profile) =>
          props.enabledDoctors.includes(profile.id)
        ).length === 0&& 
          "Your prefered doctor is not available in this clinic."}
      </FormControl>
    </>
  );
}
