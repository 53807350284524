import HealthCareProviderProfile from "./HealthCareProviderProfile";

export default class ReceptionistProfile {
    constructor (
        public readonly clinicId: string,
        public readonly clinicName: string,
        public readonly doctorId: string,
        public readonly doctorName: string,
        public readonly email: string,
        public readonly mobile: string,
        public readonly name: string,
        public readonly healthCareProviderType: string,
        public readonly associatedHealthCareProviders: HealthCareProviderProfile[]
    ) {
    }
}
