import { useMemo } from 'react'
import AppointmentListResponse from '../../models/serviceApi/AppointmentListResponse'
import RemoteResource from '../remoteResource/RemoteResource'
import AppointmentList from '../../models/domain/AppointmentList'
import useRemoteResource from '../remoteResource/useRemoteResource'
import AppointmentListMapper from '../../mappers/AppointmentListMapper'
import Path, { StringMap } from '../../models/ui/Path'
import utils from '../../Utils';

export default function useAppointmentList(query: StringMap = {}): RemoteResource<AppointmentList> {
    const userProfile = utils.retrieveUserProfile();
    const { error, isLoading, resource, refresh } = useRemoteResource<AppointmentListResponse>(
      query !== undefined
        ? new Path(['api', 'v1', 'appointments', userProfile.clinicId], query).toString()
        : null,
    );

    const mappedResource = useMemo(
      () =>
        resource ? AppointmentListMapper.mapResponseToModel(resource) : undefined,
      [resource]
    );

    return {
        resource: mappedResource,
        isLoading,
        error,
        refresh,
    };
}
