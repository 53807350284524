import React, { useEffect, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import AppointmentInfo from "../../models/domain/AppointmentInfo";
import Status from "../../models/domain/Status";
import HttpClient, { HttpGetOutput } from "../../lib/HttpClient";
import useSWR from "swr";
import Path from "../../models/ui/Path";

export default function CompleteAppointmentDialog(props: {
  appointmentInfo: AppointmentInfo;
  isOpen: boolean;
  closeFn: Function;
}) {
  //TODO: Sync-up with Sanyam on the following implementation to fetch data
  const [open, setOpen] = React.useState(props.isOpen);
  const [cancelAppointment, confirmCancellation] = React.useState(false);
  const { data, error } = useSWR<HttpGetOutput<Status>, Error>(
    cancelAppointment && props.appointmentInfo.id !== ""
      ? new Path([
          "api",
          "v1",
          "appointments",
          props.appointmentInfo.id,
          "payment",
        ]).toString()
      : null,
    HttpClient.performPut
  );

  /*
   * TODO: Feeling guilty :( is this the best way?
   * Sourced from: https://betterprogramming.pub/updating-state-from-properties-with-react-hooks-5d48693a4af8
   */
  const previousValueRef = useRef(false);
  const previousValue = previousValueRef.current;
  if (props.isOpen !== previousValue && props.isOpen !== open) {
    setOpen(props.isOpen);
  }
  useEffect(() => {
    previousValueRef.current = props.isOpen;
  });

  const currentState = () => {
    if (cancelAppointment) {
      if (error || data?.body.success == false) {
        return <span>Failed to complete appointment, contact admin.</span>;
      }
      if (!data) {
        return <span>Processing, please wait</span>;
      }
      confirmCancellation(false);
      props.closeFn(true); // Close dialog if appointment was cancelled successfully
    }
    return <span></span>;
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => confirmCancellation(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Complete appointment"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.appointmentInfo.patientName} - Are you sure you want to mark
            the payment as done?
            <br />
            {currentState()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.closeFn()}>No</Button>
          <Button onClick={() => confirmCancellation(true)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
