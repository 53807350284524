import { useMemo } from "react";
import RemoteResource from "../remoteResource/RemoteResource";
import useRemoteResource from "../remoteResource/useRemoteResource";
import Path, { StringMap } from "../../models/ui/Path";
import UpcomingUserAppointment from "../../models/domain/UpcomingUserAppointment";
import UpcomingUserAppointmentResponse from "../../models/serviceApi/UpcomingUserAppointmentResponse";
import UpcomingUserAppointmentMapper from "../../mappers/UpcomingUserAppointmentMapper";
import Status from "../../models/domain/Status";

export default function useUpcomingUserAppointment(
  query: StringMap = {},
  userProfie: any
): RemoteResource<UpcomingUserAppointment> {
  const { error, isLoading, resource, refresh } =
    useRemoteResource<UpcomingUserAppointmentResponse>(
      query !== undefined
        ? new Path(["api", "v1", "appointments", "upcoming"], query).toString()
        : null
    );

  const mappedResource = useMemo(
    () =>
      resource
        ? UpcomingUserAppointmentMapper.mapResponseToModel({
            appointment: resource.appointments.find((appointment) => {
              console.log(userProfie);

              if (
                userProfie.healthCareProviderType === "Doctor" &&
                appointment.ownerMetadata.code.startsWith("DR")
              ) {
                return true;
              } else if (
                userProfie.healthCareProviderType === "Dietitian" &&
                appointment.ownerMetadata.code.startsWith("DT")
              ) {
                return true;
              } else {
                return false;
              }
            }),

            status: resource.status,
          })
        : undefined,
    [resource]
  );

  return {
    resource: mappedResource,
    isLoading,
    error,
    refresh,
  };
}
