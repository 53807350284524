import UpcomingUserAppointments from "../models/domain/UpcomingUserAppointment";
import AppointmentMapper from "./AppointmentMapper";
import UpcomingUserAppointment from "../models/domain/UpcomingUserAppointment";

export default class UpcomingUserAppointmentMapper {
  static mapResponseToModel(response: any): UpcomingUserAppointments {
    return new UpcomingUserAppointments(
      response.appointment
        ? AppointmentMapper.mapResponseToModel(response.appointment)
        : null,
      response.status
    );
  }
}
