import Profile from './Profile'
import Status from './Status'

export default class ExistingUser {
  constructor(
    public readonly profiles: Profile[],
    public readonly status: Status,
  ) {
  }
}
