import TodayAppointmentTable from '../components/appointments/TodayAppointmentTable'
import PageWrapper from '../components/layout/PageWrapper'

export default function TodayAppointmentPage() {
  return (
    <PageWrapper>
      <TodayAppointmentTable />
    </PageWrapper>
  )

}
