import AppointmentDate from '../models/domain/AppointmentDate'
import AppointmentDateResponse from '../models/serviceApi/AppointmentDateResponse'
import AppointmentDateSlotMapper from './AppointmentDateSlotMapper'

export default class AppointmentDateMapper {
  static mapResponseToModel(response: AppointmentDateResponse): AppointmentDate {
    return new AppointmentDate(
      response.date,
      response.available,
      response.bufferAvailable,
      response.slots.map(AppointmentDateSlotMapper.mapResponseToModel),
    );
  }
}
