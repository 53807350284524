import AttendeeMetadataResponse from '../models/serviceApi/AttendeeMetadataResponse'
import AttendeeMetadata from '../models/domain/AttendeeMetadata'

export default class AttendeeMetadataMapper {
  static mapResponseToModel(response: AttendeeMetadataResponse): AttendeeMetadata {
    return new AttendeeMetadata(
      response.clinicId,
      response.appId,
      response.name,
      response.mobile,
      response.email,
    );
  }
}
