import Waitlist from "../models/domain/Waitlist";
import AttendeeMetadataMapper from "./AttendeeMetadataMapper";
import WaitlistResponse from "../models/serviceApi/WaitlistResponse";

export default class WaitlistMapper {
  static mapResponseToModel(response: WaitlistResponse): Waitlist {
    return new Waitlist(
      response.id,
      response.date,
      response.startTime,
      response.attendeeType,
      AttendeeMetadataMapper.mapResponseToModel(response.attendeeMetadata),
      response.ownerMetadata,
      response.spaceMetadata,
      response.location,
      response.note
    );
  }
}
