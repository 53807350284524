import HealthCareProviderProfile from "./HealthCareProviderProfile";

export default class DoctorConsultationSessionSummary {
    constructor(
        public readonly healthCareProvider: HealthCareProviderProfile,
        public readonly appointmentCount: Number,
        public readonly newPatientCount: Number,
        public readonly sessionTimingFormatted: String
    ) {
    }
}